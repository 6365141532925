<template>
  <!-- eslint-disable -->
<svg id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="491.52"
            viewBox="0 0 512 491.52"
        >
            <path class="cls-1"
                d="M-.5,10.24H204.3a20.48011,20.48011,0,0,1,20.48,20.48v102.4H19.98A20.48006,20.48006,0,0,1-.5,112.64Z"
                transform="translate(0.5 -10.24)"
            />
            <path class="cls-1"
                d="M286.22,10.24h204.8A20.48011,20.48011,0,0,1,511.5,30.72v102.4H306.7a20.48011,20.48011,0,0,1-20.48-20.48Z"
                transform="translate(0.5 -10.24)"
            />
            <path class="cls-1"
                d="M-.5,194.56H204.3a20.48011,20.48011,0,0,1,20.48,20.48v102.4H19.98A20.48,20.48,0,0,1-.5,296.96Z"
                transform="translate(0.5 -10.24)"
            />
            <path class="cls-1"
                d="M286.22,194.56h204.8a20.48011,20.48011,0,0,1,20.48,20.48v102.4H306.7a20.48,20.48,0,0,1-20.48-20.48Z"
                transform="translate(0.5 -10.24)"
            />
            <path class="cls-1"
                d="M-.5,378.88H204.3a20.48,20.48,0,0,1,20.48,20.48v102.4H19.98A20.48,20.48,0,0,1-.5,481.28Z"
                transform="translate(0.5 -10.24)"
            />
            <path class="cls-1"
                d="M286.22,378.88h204.8a20.48,20.48,0,0,1,20.48,20.48v102.4H306.7a20.48006,20.48006,0,0,1-20.48-20.48Z"
                transform="translate(0.5 -10.24)"
            />
        </svg>
<!-- eslint-enable -->
</template>