<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    @click:outside="handleClickCancel"
  >
    <v-card>
      <v-card-title>
        <div class="text-h5 text-wrap">
          {{ title }}
        </div>
      </v-card-title>           
      <v-card-text>
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="cancelText"
          color="primary"
          @click="handleClickCancel"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          v-if="confirmText"
          color="error"
          @click="handleClickConfirm"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
    name: 'CrvConfirmationDialog',

    props: {
        title: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        cancelText: {
            type: String,
            default: '',
        },

        confirmText: {
            type: String,
            default: '',
        },
    },

    emits: ['confirm', 'cancel'],

    data() {
        return {
            dialog: false,
        }
    },

    methods: {
        activate() {
            this.dialog = true
        },

        handleClickCancel() {
            this.dialog = false
            this.$emit('cancel')
        },

        handleClickConfirm() {
            this.dialog = false
            this.$emit('confirm')
        },
    },
}
</script>