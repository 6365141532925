<template>
  <v-btn
    color="base-white"
    icon
  >
    <v-icon :icon="dotsGrid" />
    <v-tooltip
      v-model="showTooltip"
      activator="parent"
      location="bottom"
    >
      {{ tooltip }}
    </v-tooltip>
    <v-menu
      v-model="showMenu"
      activator="parent"
      width="360"
      offset="18"
    >
      <v-card class="rounded-lg">
        <v-card-text>
          <v-container>
            <v-row>
              <template
                v-for="(submenu, submenuIndex) in localAppMenu"
                :key="submenuIndex"
              >
                <template
                  v-for="(app, index) in sort(submenu.apps)"
                  :key="index"
                >
                  <v-col
                    class="d-flex flex-column align-center"
                    cols="4"
                  >
                    <v-btn
                      variant="flat"
                      target="_blank"
                      height="100"
                      width="80"
                      :href="app.url"
                    >
                      <div class="d-flex flex-column align-center">
                        <component :is="app.iconComponent" />
                        {{ app.label }}
                      </div>
                    </v-btn>
                  </v-col>
                  <v-responsive
                    v-if="(index+1)%3 === 0"
                    width="100%"
                  />
                </template>
              </template>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-menu>    
  </v-btn>
</template>

<script>
import { mdiDotsGrid } from '@mdi/js'
import defaultAppMenu from '@v3c/helpers/defaultAppMenu'
import orderBy from 'lodash/orderBy'


export default {
    name: 'CrvAppMenu',

    props: {
        tooltip: {
            type: String,
            default: '',
        },

        appMenu: {
            type: Object,
            default: undefined,
            note: ` An array of submenus where each submenu is structured as:
                    * id: the id of a submenu (string, required)
                    * label: the label of a submenu (string, required)
                    * apps: an array of apps. Each app or submenu item is structured as:
                      - id: the id of the app (string, required)
                      - iconComponent: the icon representing the app (component, required)
                      - label: the label of the app (string, required)
                      - url: the url of the app (string, required)`,
        },

        marketCountryCode: {
            type: String,
            required: true,
            note: ` The marketCountryCode is a code defining the market of a user and ultimately the app menu
                    version that is offered to that user. This is not the same as the locale in which the app
                    menu is represented to the user. The marketCountryCode is used to pick the correct configuration
                    from the defaultAppMenu that currently resides in crv-chrome. When the appMenu is passed in from
                    the client app it is not necessary to also pass in a marketCountryCode, just pass in the appMenu
                    that is relevant for your market`,
        },
    },

    data() {
        return {
            showTooltip: false,
            showMenu: false,
            environment: undefined,
            dotsGrid: mdiDotsGrid
        }
    },

    computed: {
        localAppMenu() {
            if (!this.environment) {
                return []
            }
            if (this.appMenu) {
                return this.appMenu
            }
            return defaultAppMenu[this.marketCountryCode][this.environment]
                || defaultAppMenu[this.marketCountryCode].prd
        },
    },

    watch: {
        showMenu() {
            if (this.showMenu) {
                this.showTooltip = false
            }
        },
    },

    mounted() {
        this.environment = window.location.hostname.substring(0, 3)
    },

    methods: {
        sort(apps) {
            return orderBy(apps, 'label', 'ASC')
        },
    },
}
</script>


<style lang="scss" scoped>
@import '@v3c/assets/variables.scss';

    .crv-svg-icon {
        width: 70px !important;
        height: 70px !important;
    }
</style>



