<template>
  <div
    class="crv-app-nav-item"
    @click="handleClick"
  >
    <v-icon 
      v-if="item.iconName"
      class="mr-4"
      :icon="item.iconName" 
      size="small"
    />
    <span
      class="crv-app-nav-item_label"
      :style="labelStyles"
    >
      <slot />
    </span>
    <v-icon
      v-if="shouldShowChevron"
      :icon="mdiChevronRight"
      class="crv-app-nav-item_link-icon"
    />
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'


export default {
    name: 'CrvAppNavItem',

    props: {
        item: {
            type: Object,
            default: () => ({}),
        },

        shouldShowChevron: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['click'],

    static() {
        return {
            mdiChevronRight,
        }
    },

    computed: {
        labelStyles() {
            return {
                color: this.item.labelColor || '',
            }
        },
    },

    methods: {
        handleClick() {
            this.$emit('click', this.item)
        },
    },
}
</script>
