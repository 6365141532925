<template>
  <div class="crv-app-account-menu-content rounded-lg">
    <div class="crv-app-account-menu-content_user">
      <div class="crv-app-account-menu-content_user-label">
        {{ label }}
      </div>
      <div class="crv-app-account-menu-content_user-name">
        {{ name }}
      </div>
      <div class="divider" />
    </div>
    <slot name="customer-name">
      <div v-if="allowSwitchCustomer">
        <div
          v-if="hasCustomerName"
          class="crv-app-account-menu-content_customer"
          @click="handleClickCustomer"
        >
          <v-icon
            :icon="swapHorizontal"
            class="mr-2"
          />
          <div class="crv-app-account-menu-content_customer-content">
            {{ computedCustomerName }}
          </div>
        </div>
        <div
          v-else
          class="crv-app-account-menu-content_no-customer"
          @click="handleClickCustomer"
        >
          <v-icon
            :icon="swapHorizontal"
            class="mr-2"
          />
          <div class="crv-app-account-menu-content_no-customer-content">
            {{ selectCustomerText }}
          </div>
        </div>
        <div class="divider" />
      </div>
    </slot>
    <slot
      v-if="hasMultipleLocations"
      name="locations"
    >
      <div class="crv-app-account-menu-content_locations">
        <div class="crv-app-account-menu-content_locations-label">
          {{ locationsLabel }}
        </div>
        <div
          v-for="location in locations"
          :key="location.id"
          class="crv-app-account-menu-content_locations-location"
          :class="{ 'is-selected': location.isSelected }"
          @click="handleClickLocation(location.id)"
        >   
          <!-- TODO: replace with v-icon mdiCheckBold -->
          <check-solid :class="{ 'is-selected': location.isSelected }" />
          <div class="crv-app-account-menu-content_locations-text">
            {{ location.label }}
          </div>
        </div>
      </div>
      <div class="divider" />
    </slot>
    <div v-if="shouldShowMyCrvAndFaq">
      <div
        v-if="!isMyCrv"
        class="crv-app-account-menu-content_my-crv"
        @click="handleClickMyCrv"
      >
        <v-icon :icon="account" />
        {{ myCrvLabel }}
        <v-icon
          :icon="openInNew"
          class="link-icon"
        />
      </div>
      <div
        class="crv-app-account-menu-content_faq"
        @click="handleClickFaq"
      >
        <v-icon :icon="helpCircle" />
        {{ faqLabel }}
        <v-icon
          :icon="openInNew"
          class="link-icon"
        />
      </div>
      <div class="divider" />
    </div>
    <div
      class="crv-app-account-menu-content_logout"
      @click="handleLogout"
    >
      <v-icon
        :icon="logout"
        class="mr-2"
      />
      {{ logoutLabel }}
    </div> 
  </div>
</template>


<script>
import { mdiAccount, mdiHelpCircle, mdiLogout,mdiOpenInNew, mdiSwapHorizontal } from '@mdi/js'
import { enumCountry } from '@v3c/helpers/enums'
import { checkSolid } from '@v3c/images/icons'
import storageHelper from '@v3c/security/storageHelper'


export default {
    name: 'CrvAppAccountMenuContent',

    components: {
        checkSolid,
    },

    props: {
        name: {
            type: String,
            default: undefined,
        },

        label: {
            type: String,
            default: 'Ingelogd als',
        },

        allowSwitchCustomer: {
            type: Boolean,
            default: false,
        },

        customerName: {
            type: String,
            default: undefined,
        },

        locations: {
            type: Array,
            default: () => [],
        },

        locationsLabel: {
            type: String,
            default: 'Toon gegevens van',
        },

        myCrvLabel: {
            type: String,
            default: 'Mijn CRV',
        },

        faqLabel: {
            type: String,
            default: 'Veelgestelde vragen',
        },

        logoutLabel: {
            type: String,
            default: 'Uitloggen',
        },

        marketCountryCode: {
            type: String,
            default: 'NL',
        },

        isMyCrv: {
            type: Boolean,
            default: false,
        },

        showMyCrvAndFaq: {
            type: Boolean,
            default: true,
        },

        selectCustomerText: {
            type: String,
            default: 'Selecteer bedrijf',
        },
    },

    emits: [
        'logout', 
        'switch-customer', 
        'click-location',
        'close',
    ],

    data() {
        return {
            swapHorizontal: mdiSwapHorizontal,
            account: mdiAccount,
            openInNew: mdiOpenInNew,
            helpCircle: mdiHelpCircle,
            logout: mdiLogout,
        }
    },

    static() {
        return {
            events: {
                logout: {
                    emit: (event) => this.$emit('logout', event),
                    description: 'logout event was triggered',
                    params: 'click event',
                },
                close: {
                    emit: () => this.$emit('close'),
                    description: 'close event was triggered',
                },
                clickLocation: {
                    emit: (location) => this.$emit('click-location', location),
                    description: 'location was clicked',
                },
                switchCustomer: {
                    emit: () => this.$emit('switch-customer'),
                    description: 'user requested to switch customer',
                },
            }
        }
    },

    computed: {
        hasMultipleLocations() {
            return this.locations && this.locations.length > 1
        },

        hasCustomerName() {
            return !!this.customerName || this.hasAuthorizedCustomerName
        },

        hasAuthorizedCustomerName() {
            return storageHelper.loadAuthorizedCustomers()?.includes(storageHelper.loadCustomerNumber())
        },

        computedCustomerName() {
            return this.customerName || storageHelper.loadCustomerName()
        },

        shouldShowMyCrvAndFaq() {
            return [enumCountry.NETHERLANDS.alphaTwoCode, enumCountry.BELGIUM.alphaTwoCode]
                .includes(this.marketCountryCode) && this.showMyCrvAndFaq
        },
    },

    methods: {
        handleClickMyCrv() {
            const env = window.location.hostname.substring(0, 3)
            const countryCode = this.marketCountryCode === enumCountry.NETHERLANDS.alphaTwoCode
                ? 'nl'
                : 'be'
            return env === 'prd'
                ? window.open(`https://www.mijncrv.${countryCode}/mycrv/`, '_blank')
                : window.open(`https://${env}.mijncrv.${countryCode}/mycrv/`, '_blank')
        },

        handleClickFaq() {
            if (this.marketCountryCode === enumCountry.NETHERLANDS.alphaTwoCode) {
                window.open('https://crv4all.nl/nl/veelgestelde-vragen', '_blank')
            } else {
                window.open('https://crv4all.be/be/veelgestelde-vragen', '_blank')
            }
        },

        handleLogout(event) {
            this.events.logout.emit(event)
            this.events.close.emit()
        },

        handleClickLocation(location) {
            this.events.clickLocation.emit(location)
        },

        handleClickCustomer() {
            if (this.allowSwitchCustomer) {
                this.events.switchCustomer.emit()
                this.events.close.emit()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@v3c/assets/variables.scss';

$menu-width: $horizontal-spacing-single * 30;
$menu-border-radius: 10px;
$text-color: $base-gray-90;
$menu-item-color: $base-gray-80;
$divider-color: $base-gray-40;
$border-color: $base-gray-40;
$shadow-color: rgba(0, 0, 0, 0.24);

.crv-app-account-menu-content {
    display: flex;
    flex-direction: column;
    width: $menu-width;
    padding: $vertical-spacing-double $horizontal-spacing-double;
    background-color: $base-white;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
    border-radius: $menu-border-radius;

    &_user,
    &_customer,
    &_no-customer,
    &_locations,
    &_logout,
    &_my-crv,
    &_faq {
        width: 100%;
        min-height: $element-height-4;
        color: $text-color;
    }

    &_user {
        display: flex;
        flex-direction: column;

        &-label {
            color: $base-gray-90;
            font-size: $font-size-xs;
            font-style: $font-style-emphasis;
        }

        &-name {
            color: $base-gray-90;
            font-weight: $font-weight-strong;
            padding-bottom: $vertical-spacing-double;
        }
    }

    &_customer,
    &_no-customer {
        display: flex;
        align-items: center;
        margin: $vertical-spacing-single 0;
        padding: $vertical-spacing-single $horizontal-spacing-single;
        cursor: pointer;
        border-radius: $menu-border-radius;

        &:hover {
            background-color: $base-gray-5;
        }

        .crv-svg-icon {
            fill: $base-blue-80;
            margin-right: $horizontal-spacing-single;
            min-width: fit-content;
        }
    }

    &_no-customer {
        color: $base-gray-80;
    }

    .divider {
        border-bottom: $border-single solid $divider-color;
    }

    &_locations {
        padding-bottom: $vertical-spacing-single;

        &-label {
            color: $base-gray-90;
            font-weight: $font-weight-strong;
            margin: $vertical-spacing-single 0;
        }

        &-location {
            display: flex;
            padding: $vertical-spacing-single $horizontal-spacing-single;
            cursor: pointer;
            border-radius: $menu-border-radius;

            &.is-selected {
                background-color: $base-blue-5;

                &:hover {
                    background-color: $base-blue-5;
                }
            }

            &:hover {
                background-color: $base-gray-5;
            }

            .crv-svg-icon {
                margin-right: $horizontal-spacing-single;
                fill: $base-blue-80;
                visibility: hidden;

                &.is-selected {
                    visibility: visible;
                }
            }
        }
    }

    &_logout {
        display: flex;
        align-items: center;
        margin-top: $vertical-spacing-single;
        padding: $vertical-spacing-single $horizontal-spacing-single;
        cursor: pointer;
        border-radius: $menu-border-radius;

        &:hover {
            background-color: $base-gray-5;
        }

        .crv-svg-icon {
            margin-top: $vertical-spacing-half;
            margin-right: $horizontal-spacing-single;
            fill: $menu-item-color;
        }
    }

    &_faq {
        margin-bottom: $vertical-spacing-single;
    }

    &_my-crv {
        margin-top: $vertical-spacing-single;
    }

    &_my-crv,
    &_faq {
        display: flex;
        align-items: center;
        padding: $vertical-spacing-single $horizontal-spacing-single;
        cursor: pointer;
        border-radius: $menu-border-radius;

        .crv-svg-icon {
            margin-right: $horizontal-spacing-single;
            fill: $base-blue-80;
        }

        &:hover {
            background-color: $base-gray-5;

            .link-icon {
                fill: $base-gray-60;
            }
        }
    }

    .link-icon {
        fill: $base-white;
        margin-left: auto;
        margin-right: 0;
    }
}

</style>