<template>
  <div class="d-flex justify-center">
    <v-card
      class="d-flex flex-column align-center text-center text-balance"
      elevation="0"
      max-width="500"
    >
      <v-img
        v-if="hasImage"
        :width="imageSize.width"
        :height="imageSize.height"
      >
        <slot name="image">
          <component 
            :is="imageName"
            v-if="imageName" 
          />
        </slot>
      </v-img>
      <v-card-title v-if="hasTitle">
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>
      <v-card-text v-if="hasText">
        <slot name="text">
          {{ text }}
        </slot>
      </v-card-text>
      <v-card-actions v-if="hasActions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </div>
</template>


<script>
import * as images from '@v3c/images/fallback'


export default {
    name: 'CrvFallbackContent',

    components: {
        ...images,
    },

    props: {
        title: {
            type: String,
            default: '',
            note: `The text to be displayed just under the fallback content image, if there is one,
                    text has a slightly larger font-size than the text`,
        },

        text: {
            type: String,
            default: '',
            note: `The text to be displayed just under the heading text. If there is no heading the text is shown
                    just under the fallback content image`,
        },

        imageName: {
            type: String,
            required: false,
            default: null,
            note: 'An alternative way to provide the image.',
        },

        imageSize: {
            type: Object,
            default: () => ({
                width: 216,
                height: 162,
            }),
            note: 'Defaults to mobile 216px width, 162px height',
        },
    },

    computed: {
        hasImage() {
            return !!this.$slots.image || !!this.imageName
        },

        hasTitle() {
            return !!this.$slots.title || !!this.title
        },

        hasText() {
            return !!this.$slots.text || !!this.text
        },

        hasActions() {
            return !!this.$slots.actions
        },
    },
}
</script>


<style lang="scss">
    .text-balance {
        text-wrap: balance;
    }

</style>