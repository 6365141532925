<template>
  <!-- eslint-disable -->
<svg class="feedback crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <filter id="feedback-a" width="127.9%" height="284.6%" x="-14%" y="-92.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8"/>
        </filter>
        <filter id="feedback-b" width="126.8%" height="284.6%" x="-13.4%" y="-92.3%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8"/>
        </filter>
        <path id="feedback-d"
              d="M16.6575766,0.130640266 C16.9291659,0.138755387 17.2002377,0.159367779 17.4699315,0.192410773 L217.391866,24.6869237 C221.832683,25.2310144 225.17,29.0021761 225.17,33.4762005 L225.17,161.716616 C225.17,166.041459 222.045921,169.733588 217.780738,170.449464 L212.362731,171.358833 L134.953,159.930313 L133.431955,181.16 L128.146359,185.770841 L96.324,154.227313 L12.2696603,141.81926 C8.08083076,141.200869 4.91594195,137.703544 4.71761628,133.473962 C1.57253876,66.4006263 0,27.3864568 0,16.4314535 C0,5.23237371 5.55252552,-0.201230701 16.6575766,0.130640266 Z"/>
        <filter id="feedback-c" width="128%" height="133.9%" x="-14%" y="-17%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10.5"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <path id="feedback-f"
              d="M24.3021266,0.800981361 L208.514577,13.8516751 C213.130448,14.1786905 216.716199,18.0046476 216.74365,22.6320062 L217.5,150.128235 L217.753362,152.167109 C218.27453,156.361081 215.759301,160.338803 211.746896,161.666067 L203.903424,164.260609 L172.459986,161.403818 L168.089457,183.630246 L162.584717,186.759602 L133.471986,157.861818 L24.4990436,147.96377 C20.0498341,147.55961 16.5985219,143.904492 16.4500146,139.439433 L12.0939863,8.46990377 L17.5489006,3.24124272 C19.3543694,1.51065931 21.8074522,0.624243952 24.3021266,0.800981361 Z"/>
        <filter id="feedback-e" width="130.6%" height="133.9%" x="-15.3%" y="-16.9%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10.5"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <radialGradient id="feedback-i" cx="94.297%" cy="14.48%" r="115.988%" fx="94.297%" fy="14.48%"
                        gradientTransform="matrix(-.95038 .30624 -.29417 -.88461 1.882 -.016)">
            <stop offset="0%" stop-color="#EA7B7B"/>
            <stop offset="100%" stop-color="#DB3E3E"/>
        </radialGradient>
        <path id="feedback-h"
              d="M304.676471,67 C310.752414,67 316.069427,70.1795837 318.984566,74.9310935 C322.262735,70.8384267 327.361649,68.2087639 333.088235,68.2087639 C342.98063,68.2087639 351,76.0559034 351,85.7358406 C351,88.8016583 350.195571,91.6836266 348.781984,94.1906682 C346.683591,98.7647449 344.029175,103.146151 340.819742,107.335245 C336.796491,112.586569 328.469292,120.474821 315.838145,131 C307.300052,120.667956 300.966658,112.360935 296.837962,106.078938 C292.767715,99.8858742 290.013691,93.7220579 288.575887,87.5874886 C288.200583,86.2268206 288,84.7954879 288,83.3183127 C288,74.3059575 295.46631,67 304.676471,67 Z"/>
        <filter id="feedback-g" width="195.2%" height="193.7%" x="-47.6%" y="-46.9%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <radialGradient id="feedback-l" cx="94.297%" cy="14.48%" r="116.165%" fx="94.297%" fy="14.48%"
                        gradientTransform="matrix(-.94893 .30577 -.29831 -.86968 1.881 -.018)">
            <stop offset="0%" stop-color="#CE6A6A"/>
            <stop offset="100%" stop-color="#DB3E3E"/>
        </radialGradient>
        <path id="feedback-k"
              d="M306.676471,67 C312.752518,67 318.069609,70.2134028 320.984716,75.0154234 C324.262769,70.8791031 329.361669,68.221579 335.088235,68.221579 C344.98063,68.221579 353,76.1519126 353,85.9344749 C353,87.7883986 352.711983,89.5757992 352.177782,91.2553066 L352.272968,90.9256467 C350.678034,96.6651142 347.526958,102.277523 342.819742,107.762873 C338.112525,113.248222 329.029121,121.327265 315.569531,132 C311.237299,125.39352 302.779737,112.554421 298.837962,106.493246 C294.770522,100.23884 292.017489,94.0139513 290.578863,87.8185785 C290.201852,86.4397996 290,84.988869 290,83.4913168 C290,74.383414 297.46631,67 306.676471,67 Z"/>
        <filter id="feedback-j" width="195.2%" height="192.3%" x="-47.6%" y="-46.2%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <path id="feedback-m"
              d="M304.676471,67 C310.752414,67 316.069427,70.1795837 318.984566,74.9310935 C322.262735,70.8384267 327.361649,68.2087639 333.088235,68.2087639 C342.98063,68.2087639 351,76.0559034 351,85.7358406 C351,88.5000114 350.34608,91.1147311 349.181356,93.4399554 C347.117276,98.2853402 344.329911,102.91723 340.819742,107.335245 C336.647467,112.586607 328.320268,120.474859 315.838145,131 C307.300052,120.667956 300.966658,112.360935 296.837962,106.078938 C292.767715,99.8858742 290.013691,93.7220579 288.575887,87.5874886 C288.200583,86.2268206 288,84.7954879 288,83.3183127 C288,74.3059575 295.46631,67 304.676471,67 Z"/>
        <path id="feedback-o"
              d="M29.9,0.2875 C46.1910194,0.2875 59.3975,13.262288 59.3975,29.2675 C59.3975,34.0022419 58.2417688,38.4717839 56.1923469,42.4186829 L55.9481616,42.9947788 C53.3165092,49.2334462 52.1778545,55.9970954 52.6186202,62.7475214 L52.6659053,63.3893191 C56.0032346,62.9592215 58.17856,63.1521144 58.4827629,64.3722054 C58.7598074,65.4833703 57.1213215,66.4266996 54.326697,67.3623353 L54.2808048,67.3770635 L55.7735611,75.2943625 C56.0104861,76.5509681 55.811763,77.8508006 55.2102073,78.9792162 C54.6157393,80.0943365 53.5977517,80.9235023 52.3854156,81.2800505 L52.3854156,81.2800505 L51.1183073,81.6528672 C51.2873062,82.8960028 50.5478873,84.1196507 49.3121637,84.5186532 C47.9137786,84.9701776 46.4184916,84.43581 45.6074721,83.275002 L44.0329403,83.7365144 C42.6380003,84.1467665 41.1483418,84.0995248 39.7821994,83.6017102 C38.4353289,83.1109182 37.338001,82.1061243 36.7307599,80.8075886 L36.7307599,80.8075886 L33.0290247,72.891728 L33.0631708,72.8830775 C29.4350948,73.7033508 27.0919046,73.8972106 26.7694028,72.6037266 C26.4589768,71.3586761 28.4785609,70.0148039 31.8549881,68.6870059 L30.6314755,68.9966234 L30.1342115,68.3419609 C26.1380128,63.1498092 21.3748297,58.5962417 16.0084718,54.8376917 C6.72081847,49.9587391 0.4025,40.3380358 0.4025,29.2675 C0.4025,13.262288 13.6089806,0.2875 29.9,0.2875 Z"/>
        <filter id="feedback-n" width="181.4%" height="156.9%" x="-40.7%" y="-28.4%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <linearGradient id="feedback-p" x1="100%" x2="52.553%" y1="39.109%" y2="48.285%">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </linearGradient>
        <radialGradient id="feedback-q" cx="52.37%" cy="50%" r="62.871%" fx="52.37%" fy="50%"
                        gradientTransform="matrix(-.05298 .85741 -.9986 -.04549 1.05 .074)">
            <stop offset="0%" stop-color="#FFFBED"/>
            <stop offset="100%" stop-color="#FEDB55"/>
        </radialGradient>
        <path id="feedback-s"
              d="M43.9196578,82.6710951 L44.2491528,83.0756508 C45.3904948,84.4769965 47.3271753,84.9435623 48.9814622,84.2157117 L49.0373745,84.1911115 C50.2055247,83.6771506 50.7358498,82.3135301 50.2218889,81.1453799 C50.1418858,80.9635458 50.038769,80.7927871 49.9150894,80.6373281 L49.9150894,80.6373281 L49.9150894,80.6373281 L43.9196578,82.6710951 Z"/>
        <filter id="feedback-r" width="693.2%" height="1079.3%" x="-305.3%" y="-498.9%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <linearGradient id="feedback-t" x1="98.119%" x2="51.289%" y1="61.686%" y2="61.686%">
            <stop offset="0%" stop-color="#C4BCB8"/>
            <stop offset="100%" stop-color="#928782"/>
        </linearGradient>
        <path id="feedback-v"
              d="M29.9,0.2875 C46.1910194,0.2875 59.3975,13.262288 59.3975,29.2675 C59.3975,34.0022419 58.2417688,38.4717839 56.1923469,42.4186829 L55.9481616,42.9947788 C53.3165092,49.2334462 52.1778545,55.9970954 52.6186202,62.7475214 L52.6659053,63.3893191 C56.0032346,62.9592215 58.17856,63.1521144 58.4827629,64.3722054 C58.7598074,65.4833703 57.1213215,66.4266996 54.326697,67.3623353 L54.2808048,67.3770635 L55.7735611,75.2943625 C56.0104861,76.5509681 55.811763,77.8508006 55.2102073,78.9792162 C54.6157393,80.0943365 53.5977517,80.9235023 52.3854156,81.2800505 L52.3854156,81.2800505 L51.1183073,81.6528672 C51.2873062,82.8960028 50.5478873,84.1196507 49.3121637,84.5186532 C47.9137786,84.9701776 46.4184916,84.43581 45.6074721,83.275002 L44.0329403,83.7365144 C42.6380003,84.1467665 41.1483418,84.0995248 39.7821994,83.6017102 C38.4353289,83.1109182 37.338001,82.1061243 36.7307599,80.8075886 L36.7307599,80.8075886 L33.0290247,72.891728 L33.0631708,72.8830775 C29.4350948,73.7033508 27.0919046,73.8972106 26.7694028,72.6037266 C26.4589768,71.3586761 28.4785609,70.0148039 31.8549881,68.6870059 L30.6314755,68.9966234 L30.1342115,68.3419609 C26.1380128,63.1498092 21.3748297,58.5962417 16.0084718,54.8376917 C6.72081847,49.9587391 0.4025,40.3380358 0.4025,29.2675 C0.4025,13.262288 13.6089806,0.2875 29.9,0.2875 Z"/>
        <filter id="feedback-u" width="181.4%" height="156.9%" x="-40.7%" y="-28.4%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <path id="feedback-x"
              d="M45.0696578,82.1825973 L45.2683563,82.657913 C45.9276517,84.2350431 47.6854708,85.0438959 49.3121637,84.5186532 C50.7000733,84.0705114 51.4619055,82.5820976 51.0137636,81.1941881 C50.9772258,81.0810294 50.9330867,80.9704679 50.8816476,80.8632582 L50.7167114,80.5194977 L50.7167114,80.5194977 L45.0696578,82.1825973 Z"/>
        <filter id="feedback-w" width="759.4%" height="1058.4%" x="-337.1%" y="-487%" filterUnits="objectBoundingBox">
            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="8"/>
            <feColorMatrix in="shadowBlurOuter1"
                           values="0 0 0 0 0.149019608   0 0 0 0 0.137254902   0 0 0 0 0.133333333  0 0 0 0.0987762238 0"/>
        </filter>
        <linearGradient id="feedback-y" x1="100%" x2="52.553%" y1="39.483%" y2="48.344%">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </linearGradient>
        <radialGradient id="feedback-z" cx="52.37%" cy="50%" r="63.085%" fx="52.37%" fy="50%"
                        gradientTransform="matrix(-.0528 .8545 -.9986 -.04518 1.05 .075)">
            <stop offset="0%" stop-color="#FFFBED"/>
            <stop offset="100%" stop-color="#FEDB55"/>
        </radialGradient>
        <linearGradient id="feedback-A" x1="98.119%" x2="51.289%" y1="61.686%" y2="61.686%">
            <stop offset="0%" stop-color="#C4BCB8"/>
            <stop offset="100%" stop-color="#928782"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 27)">
        <ellipse cx="256" cy="308" fill="#F7F6F6" rx="256" ry="50"/>
        <ellipse cx="333.999" cy="318.122" fill="#BCB4B1" filter="url(#feedback-a)" opacity=".094" rx="86" ry="13"
                 transform="rotate(3 333.999 318.122)"/>
        <ellipse cx="181.66" cy="297.823" fill="#BCB4B1" filter="url(#feedback-b)" opacity=".094" rx="89.5" ry="13"
                 transform="rotate(-10 181.66 297.823)"/>
        <g transform="matrix(-1 0 0 1 288 68)">
            <use fill="#000" filter="url(#feedback-c)" xlink:href="#feedback-d"/>
            <use fill="#E4E0DE" xlink:href="#feedback-d"/>
            <path fill="#FFF"
                  d="M8.855,2.795 C9.22635179,2.795 9.59733585,2.81836003 9.9657545,2.86494169 L212.365754,28.4557463 C216.79153,29.0153271 220.11,32.7797936 220.11,37.2408046 L220.11,162.177544 C220.11,167.068026 216.145481,171.032544 211.255,171.032544 C210.838153,171.032544 210.421827,171.00311 210.009127,170.944461 L130.163,159.597 L128.146357,185.770841 L87.011,153.465 L7.60912681,142.181243 C3.24383179,141.560887 -1.23639117e-15,137.82348 0,133.414326 L0,11.65 C-2.37526809e-15,6.75951854 3.96451854,2.795 8.855,2.795 Z"/>
            <path fill="#D2CCC8"
                  d="M7.7778086 1.01720621L144.648846 25.6804268C147.791882 26.2467802 149.960382 29.149177 149.612397 32.3238165 149.293909 35.2293502 146.680323 37.3265653 143.77479 37.0080776 143.653598 36.9947932 143.532901 36.9773251 143.412916 36.9557046L6.54187801 12.2924841C3.39884232 11.7261306 1.2303419 8.82373378 1.57832747 5.64909433 1.89681523 2.74356061 4.51040104.646345488 7.41593477.964833251 7.53712661.978117599 7.65782323.995585675 7.7778086 1.01720621zM27.6508364 37.0291961L142.666818 58.6259908C145.921257 59.2370838 148.161262 62.2444469 147.814856 65.5375935 147.502494 68.5071008 144.842013 70.6611427 141.872506 70.3487805 141.72766 70.3335443 141.58349 70.3124612 141.440347 70.285583L26.4243654 48.6887883C23.1699259 48.0776953 20.9299215 45.0703321 21.2763272 41.7771856 21.5886893 38.8076783 24.2491702 36.6536364 27.2186776 36.9659985 27.363523 36.9812348 27.5076935 37.0023179 27.6508364 37.0291961zM67.3583 78.4609877L139.61929 91.6023319C142.791413 92.1792124 144.978855 95.1088574 144.630728 98.3141598 144.313164 101.238052 141.685445 103.350899 138.761553 103.033336 138.634995 103.01959 138.508971 103.001311 138.383723 102.978534L66.1227325 89.8371893C62.9506094 89.2603088 60.7631677 86.3306638 61.1112951 83.1253614 61.4288586 80.2014691 64.0565777 78.0886222 66.9804699 78.4061857 67.1070278 78.4199311 67.2330521 78.4382102 67.3583 78.4609877z"
                  transform="rotate(-3 758.85 -911.602)"/>
        </g>
        <g transform="translate(233 114)">
            <use fill="#000" filter="url(#feedback-e)" xlink:href="#feedback-f"/>
            <use fill="#E4E0DE" xlink:href="#feedback-f"/>
            <path fill="#FFF"
                  d="M19.24,4.945 C19.4438988,4.945 19.6477367,4.95204258 19.8511493,4.96611515 L203.276149,17.655895 C207.918349,17.9770535 211.52,21.836484 211.52,26.4897799 L211.52,155.477449 C211.52,160.36793 207.555481,164.332449 202.665,164.332449 C202.461101,164.332449 202.257263,164.325406 202.053851,164.311333 L166.951,161.883 L162.69024,186.674955 L125.742,159.032 L18.6288507,151.621554 C13.9866509,151.300395 10.385,147.440965 10.385,142.787669 L10.385,13.8 C10.385,8.90951854 14.3495185,4.945 19.24,4.945 Z"/>
            <g fill="#A29894" transform="rotate(17 -22.736 107.395)">
                <path d="M9.82063369,1.31769104 L123.177873,25.6640656 C125.871379,26.2425652 127.585931,28.8950499 127.007431,31.5885561 C126.970607,31.7600097 126.924767,31.9294023 126.870103,32.0960281 C125.887562,35.0909674 122.803212,36.8615204 119.721497,36.1996431 L6.36425783,11.8532685 C3.67075165,11.2747689 1.95620001,8.62228422 2.53469954,5.92877804 C2.57152362,5.75732437 2.61736335,5.58793182 2.6720278,5.42130597 C3.6545687,2.42636673 6.73891888,0.655813746 9.82063369,1.31769104 Z"
                      transform="matrix(-1 0 0 1 129.542 0)"/>
                <path d="M19.9793119,29.2912113 L156.315802,59.1754098 C159.137694,59.7939528 160.923861,62.5829767 160.305318,65.4048689 C160.271576,65.558805 160.230895,65.7111375 160.183399,65.8614005 C159.206051,68.9534274 156.031782,70.7896241 152.864172,70.0953016 L16.5276823,40.211103 C13.7057901,39.59256 11.9196226,36.8035361 12.5381657,33.9816439 C12.5719076,33.8277078 12.6125888,33.6753753 12.6600849,33.5251124 C13.6374327,30.4330854 16.8117015,28.5968887 19.9793119,29.2912113 Z"
                      transform="matrix(-1 0 0 1 172.843 0)"/>
                <path d="M30.4094791,78.1179871 L90.522115,90.9679418 C93.2423011,91.5494214 94.9760653,94.2259503 94.3945858,96.9461364 C94.3577956,97.1182423 94.31201,97.2883015 94.2574171,97.4556142 C93.2709934,100.478746 90.1612463,102.269195 87.0515095,101.604443 L26.9388737,88.7544883 C24.2186876,88.1730087 22.4849233,85.4964799 23.0664029,82.7762937 C23.103193,82.6041879 23.1489787,82.4341287 23.2035715,82.2668159 C24.1899953,79.2436841 27.2997424,77.4532353 30.4094791,78.1179871 Z"
                      transform="matrix(-1 0 0 1 117.461 0)"/>
            </g>
        </g>
        <g transform="rotate(8 319.5 99)">
            <use fill="#000" filter="url(#feedback-g)" xlink:href="#feedback-h"/>
            <use fill="#BCB4B1" xlink:href="#feedback-h"/>
            <use fill="url(#feedback-i)" xlink:href="#feedback-h"/>
        </g>
        <g transform="rotate(8 321.5 99.5)">
            <use fill="#000" filter="url(#feedback-j)" xlink:href="#feedback-k"/>
            <use fill="#BCB4B1" xlink:href="#feedback-k"/>
            <use fill="url(#feedback-l)" xlink:href="#feedback-k"/>
        </g>
        <g transform="rotate(8 319.5 99)">
            <use fill="#BCB4B1" xlink:href="#feedback-m"/>
            <use fill="url(#feedback-i)" xlink:href="#feedback-m"/>
        </g>
        <g transform="translate(188)">
            <use fill="#000" filter="url(#feedback-n)" xlink:href="#feedback-o"/>
            <path stroke="#979797" stroke-width="1.035"/>
            <path fill="url(#feedback-p)"
                  d="M33.0290247,72.891728 L36.8619819,81.0881961 C37.3826405,82.2015824 38.3462149,83.0457014 39.5184325,83.4153236 C40.7192662,83.7939691 42.0037514,83.8187078 43.2182743,83.4865813 L50.3765419,81.5290636 C51.868305,81.1211223 53.1877587,80.2408669 54.1372816,79.0201377 C55.0650536,77.827372 55.4211991,76.2868877 55.1111025,74.8079384 L53.4967526,67.1085902 L53.4967526,67.1085902 L33.0290247,72.891728 Z"/>
            <path fill="url(#feedback-q)"
                  d="M29.9,0.2875 C46.1910194,0.2875 59.3975,13.262288 59.3975,29.2675 C59.3975,34.0022419 58.2417688,38.4717839 56.1923469,42.4186829 L55.9481616,42.9947788 C53.2287874,49.4414018 52.1036031,56.4485886 52.6679647,63.4225 L52.6679647,63.4225 L30.6314755,68.9966234 L30.1342115,68.3419609 C26.1380128,63.1498092 21.3748297,58.5962417 16.0084718,54.8376917 C6.72081847,49.9587391 0.4025,40.3380358 0.4025,29.2675 C0.4025,13.262288 13.6089806,0.2875 29.9,0.2875 Z"/>
            <use fill="#000" filter="url(#feedback-r)" xlink:href="#feedback-s"/>
            <use fill="#7F746F" xlink:href="#feedback-s"/>
            <path stroke="#7F4B00" stroke-width="1.035"
                  d="M39.5299817,66.4971527 L31.7581518,50.3257699 C30.3178709,47.328878 28.7340103,44.4031322 27.0120805,41.5587117 L26.444221,40.6206764 C25.7038917,39.3977415 24.1123515,39.0065124 22.8894167,39.7468418 C22.3749019,40.0583141 21.9849114,40.5392364 21.786439,41.1069944 C21.0979465,43.0765223 22.136431,45.2312736 24.1059589,45.919766 C24.5422365,46.0722766 25.0027959,46.1434378 25.4647583,46.1297146 L27.8005041,46.0603284 C32.9353788,45.9077906 37.9488023,44.4623817 42.3767572,41.8578816 L45.9711478,39.7436797 C47.4590415,38.8685084 47.9557511,36.9528657 47.0805798,35.464972 C46.7814,34.9563313 46.3443407,34.5427768 45.8199545,34.2721423 C44.1257344,33.3977592 42.0434701,34.0623684 41.169087,35.7565885 C40.9164548,36.2460932 40.7846323,36.7889472 40.7846323,37.3397992 L40.7846323,40.3471319 C40.7846323,45.7548365 41.2084468,51.1542245 42.052183,56.4957017 L43.4952559,65.6314262 L43.4952559,65.6314262"/>
            <path fill="url(#feedback-t)"
                  d="M42.2421035,70.4145877 C46.2950922,70.4145877 50.0663566,70.1130309 52.9621643,69.9667762 C56.6009371,69.7829974 58.8820873,69.6406411 58.8820873,68.3459311 C58.8820873,66.0208652 51.8251997,65.5557847 42.6793643,65.5557847 C33.5335288,65.5557847 26.1193643,66.3492587 26.1193643,68.6607847 C26.1193643,69.9036882 28.3649322,69.7733969 31.6518062,69.8899306 C34.4777921,69.9901238 38.0139678,70.4145877 42.2421035,70.4145877 Z"
                  transform="rotate(-14 42.5 67.985)"/>
        </g>
        <g transform="translate(187)">
            <use fill="#000" filter="url(#feedback-u)" xlink:href="#feedback-v"/>
            <use fill="#000" filter="url(#feedback-w)" xlink:href="#feedback-x"/>
            <use fill="#7F746F" xlink:href="#feedback-x"/>
            <path stroke="#979797" stroke-width="1.035"/>
            <path fill="url(#feedback-y)"
                  d="M33.0290247,72.891728 L36.7307599,80.8075886 C37.338001,82.1061243 38.4353289,83.1109182 39.7821994,83.6017102 C41.1483418,84.0995248 42.6380003,84.1467665 44.0329403,83.7365144 L52.3854156,81.2800505 C53.5977517,80.9235023 54.6157393,80.0943365 55.2102073,78.9792162 C55.8091974,77.855613 55.9782208,76.5522414 55.6856457,75.3130186 L53.8120013,67.3770635 L53.8120013,67.3770635 L33.0290247,72.891728 Z"/>
            <path fill="url(#feedback-z)"
                  d="M29.9,0.2875 C46.1910194,0.2875 59.3975,13.262288 59.3975,29.2675 C59.3975,34.0022419 58.2417688,38.4717839 56.1923469,42.4186829 L55.9481616,42.9947788 C53.2287874,49.4414018 52.1036031,56.4485886 52.6679647,63.4225 L52.6679647,63.4225 L31.05,69.2311635 L30.49915,68.5313375 C26.3249222,63.2960999 21.431465,58.6781732 15.9646509,54.8144634 C6.70093677,49.9284384 0.4025,40.3206044 0.4025,29.2675 C0.4025,13.262288 13.6089806,0.2875 29.9,0.2875 Z"/>
            <path stroke="#7F4B00" stroke-width="1.035"
                  d="M39.5299817,66.4971527 L31.7581518,50.3257699 C30.3178709,47.328878 28.7340103,44.4031322 27.0120805,41.5587117 L26.444221,40.6206764 C25.7038917,39.3977415 24.1123515,39.0065124 22.8894167,39.7468418 C22.3749019,40.0583141 21.9849114,40.5392364 21.786439,41.1069944 C21.0979465,43.0765223 22.136431,45.2312736 24.1059589,45.919766 C24.5422365,46.0722766 25.0027959,46.1434378 25.4647583,46.1297146 L27.8005041,46.0603284 C32.9353788,45.9077906 37.9488023,44.4623817 42.3767572,41.8578816 L45.9711478,39.7436797 C47.4590415,38.8685084 47.9557511,36.9528657 47.0805798,35.464972 C46.7814,34.9563313 46.3443407,34.5427768 45.8199545,34.2721423 C44.1257344,33.3977592 42.0434701,34.0623684 41.169087,35.7565885 C40.9164548,36.2460932 40.7846323,36.7889472 40.7846323,37.3397992 L40.7846323,40.3471319 C40.7846323,45.7548365 41.2084468,51.1542245 42.052183,56.4957017 L43.4952559,65.6314262 L43.4952559,65.6314262"/>
            <path fill="url(#feedback-A)"
                  d="M42.2492466,70.4136646 C51.3950821,70.4136646 58.8892303,70.6701199 58.8892303,68.3450541 C58.8892303,66.0199882 51.8323428,65.5549077 42.6865073,65.5549077 C33.5406719,65.5549077 26.1265073,66.3483816 26.1265073,68.6599077 C26.1265073,70.9714337 33.1034112,70.4136646 42.2492466,70.4136646 Z"
                  transform="rotate(-14 42.508 68.014)"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>