<script>
// For more information on implementing html2Pdf.js:
// https://ekoopmans.github.io/html2pdf.js
// https://raw.githack.com/MrRio/jsPDF/master/docs/jsPDF.html
import '@v3c/assets/OpenSans-Regular-normal.js'

import html2pdf from 'html2pdf.js'

export default {
    name: 'CrvHtmlToPdf',

    props: {
        name: {
            type: String,
            required: true,
            note: 'required PDF Document name',
        },

        format: {
            type: String,
            default: 'a4',
            note: 'default paper-format, default "a4", could be "letter" for international paper',
        },

        orientation: {
            type: String,
            default: 'portrait',
            note: 'default paper-orientation, default "portrait", could be "landscape"',
        },

        pageNumberLabel: {
            type: String,
            default: 'Pagina',
            note: 'Word shown in front of the page number, can be set for different locales"',
        },
    },

    methods: {
        generatePdf() {
            const pageNumberLabel = this.pageNumberLabel
            const pdfOptions = { // https://ekoopmans.github.io/html2pdf.js/#options
                pagebreak: {
                    mode: ['css', 'legacy'],
                    after: '.pagebreak'
                },
                margin: [5, 3],
                // Minimum top & bottom print-margins by Epson: 4.2 mm
                // Minimum left & right margins by Epson: 3.0 mm
                // Source: http://support2.epson.net/manuals/english/sidm/lq_350/useg/html/apspe_2.htm
                image: { type: 'jpeg', quality: 0.98 },
                enableLinks: true,
                html2canvas: { scale: 2 },
                jsPDF: {
                    unit: 'mm',
                    format: this.format,
                    orientation: this.orientation
                },
            }
            html2pdf()
                .set(pdfOptions)
                .from(this.$refs.pdfPrint)
                .toPdf().get('pdf').then(function (pdf) {
                    const totalPages = pdf.internal.getNumberOfPages()
                    pdf.setFontSize(8)
                    // Set font: https://stackoverflow.com/questions/58449153/jspdf-error-font-does-not-exist-in-vfs-vuejs
                    // pdf.addFileToVFS('OpenSans-Regular-normal.ttf', openSansRegularFont)
                    // pdf.addFont('OpenSans-Regular-normal.ttf', 'OpenSans', '400')
                    // Font OpenSans-Regular is now being set with the import './OpenSans-Regular-normal.js'
                    pdf.setFont('OpenSans-Regular')

                    pdf.setTextColor(38, 35, 32)
                    for (let page = 1; page <= totalPages; page++) {
                        pdf.setPage(page)
                        // pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth()/2.25, // centered
                        pdf.text(`${pageNumberLabel} ${page} / ${totalPages}`, pdf.internal.pageSize.getWidth()/1.15, // bottom right
                            pdf.internal.pageSize.getHeight()-9.5
                        )
                    }
                })
                .save(this.name)
        }
    }
}
</script>

<template>
  <div
    ref="pdfPrint"
    class="pdf-print"
  >
    <slot />
  </div>
</template>
