<template>
  <v-navigation-drawer
    v-model="showSideMenu"
    :rail="collapsed || $vuetify.display.mdAndDown"
    permanent
    location="left"
    color="base-gray-5"
  >
    <v-list nav>
      <template
        v-for="(item) in sideMenu"
        :key="item.key"
      >
        <v-divider
          v-if="item.isDivider"
          class="my-4"
        />
        <v-list-item
          v-else
          color="base-brand-blue"
          :active="getActive(item.key)"
          :value="item.key"
          :title="item.label"
          @click="handleClickMenuItem(item)"
        >
          <template #prepend>
            <v-icon
              :icon="item.iconName"
            />
          </template>
        </v-list-item>
      </template>
    </v-list>
    <template #append>
      <v-list
        v-if="$vuetify.display.lgAndUp"
        nav
        class="pa-2"
      >
        <v-list-item
          value="collapse"
          color="base-brand-blue"
          :prepend-icon="prependIcon"
          :title="collapseLabel"
          @click.stop="handleClickCollapse"
        />
      </v-list>
    </template>
  </v-navigation-drawer>

  <v-navigation-drawer
    v-model="showFixedSubmenu"
    location="left"
    temporary
    class="pt-4"
  >
    <template v-if="activeMenuItem">
      <template
        v-for="(group, groupKey) in activeMenuItem.fixedSubmenu"
        :key="groupKey"
      >
        <div
          v-if="group.header"
          class="text-title pl-4"
        >
          {{ group.header }}
        </div>
        <v-list
          nav
          density="compact"
        >
          <v-list-item
            v-for="(item, itemKey) in group.items"
            :key="itemKey"
            color="base-brand-blue"
            :active="getActive(itemKey)"
            :value="itemKey"
            :title="item.label"
            @click="handleClickMenuItem(item)"
          />
        </v-list>
      </template>
    </template>
  </v-navigation-drawer>
</template>


<script>
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

export default {
    name: 'CrvAppSideMenu',

    props: {
        collapseLabel: {
            type: String,
            default: '',
        },

        isCollapsed: {
            type: Boolean,
            default: false,
        },

        sideMenu: {
            type: Array,
            default: () => [],
        },

        activeMenuId: {
            type: String,
            default: '',
            note: 'The id (key) of the active menu item',
        },
    },

    emits: [
        'navigate'
    ],

    static() {
        return {
            mdiChevronLeft,
            mdiChevronRight,
        }
    },

    data() {
        return {
            showSideMenu: true,
            activeMenuItem: undefined,
            showFixedSubmenu: false,
            collapsed: this.isCollapsed,
        }
    },

    computed: {
        prependIcon() {
            return this.collapsed ? mdiChevronRight : mdiChevronLeft
        },
    },

    methods: {
        handleClickCollapse() {
            this.collapsed = !this.collapsed
        },

        handleClickMenuItem(item) {
            if (item.fixedSubmenu) {
                this.activeMenuItem = item
                this.showFixedSubmenu = true
            } else {
                this.activeMenuItem = undefined
                this.showFixedSubmenu = false
                this.$emit('navigate', item)
            }
        },

        getActive(key) {
            return key === this.activeMenuId
        },
    },
}
</script>
