<template>
  <!-- eslint-disable -->
<svg class="not-allowed crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <ellipse id="no-access-a" cx="256" cy="50.721" rx="256" ry="50"/>
        <linearGradient id="no-access-c" x1="0%" x2="100%" y1="94.168%" y2="94.168%">
            <stop offset="0%" stop-color="#5D5551"/>
            <stop offset="100%" stop-color="#7F746F"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <rect width="512" height="385" fill="#FFF"/>
        <g transform="translate(0 104)">
            <g transform="translate(0 180.279)">
                <mask id="no-access-b" fill="#fff">
                    <use xlink:href="#no-access-a"/>
                </mask>
                <use fill="#F7F6F6" xlink:href="#no-access-a"/>
                <polygon fill="#E4E0DE" points="314.43 -3.603 352.421 -5 369.526 103.721 3.526 103.721"
                         mask="url(#no-access-b)"/>
            </g>
            <g transform="translate(335)">
                <path fill="url(#no-access-c)"
                      d="M27,64 L39,64 L39,219 C37.6666667,220.333333 35.6666667,221 33,221 C30.3333333,221 28.3333333,220.333333 27,219 L27,64 Z"/>
                <circle cx="32" cy="32" r="32" fill="#FFF" stroke="#DB3E3E" stroke-width="9.143"/>
                <line x1="14" x2="52.861" y1="11" y2="49.861" stroke="#DB3E3E" stroke-linecap="square"
                      stroke-width="9.143"/>
            </g>
            <g transform="translate(143 141)">
                <path fill="#A29894"
                      d="M239.430108,-6.39488462e-14 C240.84942,-0.0239490079 242,1.29633795 242,2.94927301 L242,105.705632 L241.999,105.705 L242,105.71 L239,107.705 L238.999,105.467 L236.860215,105.298958 L236.860215,89.4114611 L8.13978495,74.6366427 L8.139,85.5 L8.14,85.5 L8.14,87.21 L5.14,89.205 L5.14,86.964 L3,86.7952831 L3,6.749 L1,6.5 L3.95397935,4.54916088 C4.39542668,4.21590342 4.95765787,4.01118104 5.56989247,4.00070825 L8.13978495,3.95674804 L236.860215,0.0442897488 L239.430108,-6.39488462e-14 Z M8,53.8514279 L8,67.8511225 L237,80.9021887 L237,62.9021887 L8,53.8514279 Z M8,30.8217006 L8,46.9044686 L237,54.1185013 L237,33.9021887 L8,30.8217006 Z M237,8.89923218 L8,10.9044686 L8,23.9044686 L237,25.1560882 L237,8.89923218 Z"/>
                <polygon fill="#928782" points="5 89.17 8 87.175 8 75 5 75"/>
                <polygon fill="#928782" points="5 69.87 8 67.875 8 55.7 5 55.7"/>
                <polygon fill="#928782" points="5 48.87 8 46.875 8 31.707 5 31.707"/>
                <polygon fill="#928782" points="5 25.87 8 23.875 8 11.7 5 11.7"/>
                <path fill="#BCB4B1"
                      d="M5.13978495,76.6366427 L5.13978495,89.2019573 L0,88.7952831 L0,8.38666698 C0,7.09321691 1.15058005,6.0249868 2.56989247,6.00070825 L5.13978495,5.95674804 L233.860215,2.04428975 L236.430108,2 C237.84942,1.97605099 239,3.29633795 239,4.94927301 L239,107.705632 L233.860215,107.298958 L233.860215,91.4114611 L5.13978495,76.6366427 Z M5,69.8511225 L234,82.9021887 L234,10.8992322 L5,12.9044686 L5,69.8511225 Z M5,48.9044686 L234,56.1185013 L234,64.9021887 L5,55.8514279 L5,48.9044686 Z M5,25.9044686 L234,27.1560882 L234,35.9021887 L5,32.8217006 L5,25.9044686 Z"/>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>