<template>
  <!-- eslint-disable -->
<svg class="empty-barn crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <defs>
        <linearGradient id="empty-barn-a" x1="100%" x2="0%" y1="52.491%" y2="52.491%">
            <stop offset="0%" stop-color="#D39688"/>
            <stop offset="100%" stop-color="#BB5E48"/>
        </linearGradient>
        <linearGradient id="empty-barn-b" x1="100%" x2="0%" y1="46.516%" y2="46.516%">
            <stop offset="0%" stop-color="#D9D5D3"/>
            <stop offset="100%" stop-color="#BAB0AC"/>
        </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 54)">
        <ellipse cx="256" cy="281" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(30)">
            <path fill="#BCB4B1"
                  d="M1.86047668,222.877551 L7.07996887,219.510204 L459.091983,262.022959 L456.718874,264.127551 L1.86047668,222.877551 Z M31,186.1 L34,184 L440,221 L437,223.1 L31,186.1 Z"/>
            <polygon fill="#E4E0DE" points="31 186.086 437 223.112 437 259.112 31 222.086"/>
            <polygon fill="#E4E0DE" points="1 222.35 456 263.845 456 299.845 1 258.35"/>
            <path fill="#D2CCC8"
                  d="M456,263.84 L459,262 L459,297.486181 L456,299.84 L456,263.84 Z M437,222.84 L440,221 L440,256.486181 L437,258.84 L437,222.84 Z"/>
            <path fill="#928782"
                  d="M419.6 207.12L422.6 205.55 422.6 216.554857 419.6 218.329401 419.6 207.12zM421 71.6649942L424 70.05 424 81.0690968 421 82.8436411 421 71.6649942zM113.38 99.7620668L113 99.25 113.38 98.9893293 113.38 52 116.674819 52 116.674819 97.0603065C134.100848 99.7565441 144.845908 105.736427 148.91 115 154.148636 129 147.191938 150.146667 128.039905 178.44L123.69 179.9 122.467113 178.462538 135.11 158.303163 143.11 131 141.828874 115 116.674819 102.208354 116.674819 177.739118 113.38 179.389118 113.38 99.7620668zM243.63 114.168498L243.298659 114 242 112.25 243.63 111.13186 243.63 64 246.924819 64 246.924819 110.261299C263.615596 113.047092 273.94399 118.959992 277.91 128 283.148636 142 276.191938 163.146667 257.039905 191.44L252.69 192.9 251.467113 191.462538 264.11 171.303163 272.11 144 270.828874 128 246.924819 115.844019 246.924819 189.739118 243.63 191.389118 243.63 114.168498zM377.174819 121.072152L377.28 121C394.938636 123.666667 405.815303 129.666667 409.91 139 415.148636 153 408.191938 174.146667 389.039905 202.44L384.69 203.9 383.467113 202.462538 396.11 182.303163 404.11 155 402.828874 139 377.174819 125.954088 377.174819 201.269118 373.88 202.919118 373.88 75.53 377.174819 75.53 377.174819 121.072152zM351.7 200.889118L351.7 73.5 354.994819 73.5 354.994819 199.239118 351.7 200.889118zM221.46 188.989118L221.46 61.6 224.754819 61.6 224.754819 187.339118 221.46 188.989118z"/>
            <path fill="#7F746F"
                  d="M34,171.96 L37.6,170 L422.6,205.55 L419.6,207.13 L34,171.96 Z M15,34.65 L18,33 L424,70.07 L421.011463,71.66 L15,34.65 Z"/>
            <path fill="#A29894"
                  d="M419.614334,207.085672 L419.614334,218.265797 L362.802048,213.084675 L362.802048,291.345544 L351.716724,290.334594 L351.716724,212.073724 L232.549488,201.206005 L232.549488,279.466875 L221.464164,278.455924 L221.464164,200.195054 L102.296928,189.327335 L102.296928,267.588205 L91.2116041,266.577254 L91.2116041,188.316385 L34.3993174,183.135263 L34.3993174,171.955139 L102.296928,178.147211 L102.296928,53.7683292 L15,45.8070932 L15,34.626969 L421,71.6530349 L421,82.8331591 L373.887372,78.536619 L373.887372,123.257116 C396.938223,126.873811 408.463649,134.747674 408.463649,146.878705 C408.463649,163.560151 400.498638,182.563783 384.568618,203.889601 L419.614334,207.085672 Z M400.195409,147.640837 C400.195409,139.457453 391.426063,134.109195 373.887372,131.596065 L373.887372,202.915501 L374.436796,202.965607 C391.609202,182.604353 400.195409,164.162764 400.195409,147.640837 Z M340.631399,199.882649 L340.631399,75.5037672 L243.634812,66.6579494 L243.634812,111.476946 C265.761886,115.189293 276.825423,122.9882 276.825423,134.873666 C276.825423,151.555113 268.860413,170.558745 252.930392,191.884562 L340.631399,199.882649 Z M268.557183,135.635798 C268.557183,127.670842 260.249726,122.391684 243.634812,119.798325 L243.634812,189.962394 C260.249726,169.996187 268.557183,151.887322 268.557183,135.635798 Z M210.37884,188.00398 L210.37884,63.6250976 L113.382253,54.7792798 L113.382253,179.158162 L113.931677,179.208268 C131.104083,158.847014 139.690289,140.405424 139.690289,123.883498 C139.690289,115.700114 130.920944,110.351856 113.382253,107.838725 L113.382253,99.4997767 C136.433104,103.116472 147.958529,110.990335 147.958529,123.121365 C147.958529,139.802812 139.993519,158.806444 124.063498,180.132261 L210.37884,188.00398 Z M362.802048,201.90455 L362.802048,77.5256684 L351.716724,76.5147178 L351.716724,200.8936 L362.802048,201.90455 Z M232.549488,190.025881 L232.549488,65.6469988 L221.464164,64.6360482 L221.464164,189.01493 L232.549488,190.025881 Z"/>
            <path fill="#D2CCC8"
                  d="M125.478598,38.7148782 L195.767928,184.74771 L183.710082,183.648068 L112.731641,37.5523913 L4,27.6363694 L4,22.0514637 L112.246377,31.9232309 C114.343689,30.2528647 115.940659,29.4676863 117.037287,29.5676957 C118.133915,29.6677051 119.730886,30.744162 121.828198,32.7970662 L241.061911,43.6708478 C243.159223,42.0004815 244.756193,41.2153032 245.852821,41.3153126 C246.949449,41.415322 248.54642,42.4917789 250.643732,44.5446831 L432,61.0838682 L432,66.6687738 L254.294132,50.4624951 L324.583462,196.495327 L312.525616,195.395685 L241.547175,49.3000082 L125.478598,38.7148782 Z"/>
            <path fill="#A29894"
                  d="M338.690589,51.8201948 L379.690589,51.8201948 C380.357255,52.4607839 380.690589,53.8234801 380.690589,55.9082834 C380.690589,57.9930866 380.357255,59.3557828 379.690589,59.9963719 L338.690589,59.9963719 C338.023922,59.0516037 337.690589,57.6889075 337.690589,55.9082834 C337.690589,54.1276592 338.023922,52.764963 338.690589,51.8201948 Z"
                  transform="rotate(49 359.19 55.908)"/>
            <path fill="#BCB4B1"
                  d="M343.418118,56.4875539 C342.513966,54.9625513 342,53.1483061 342,50.9939705 C342,41.7870083 351.387302,36.2471472 360.5,37 C369.572497,37.7495316 376.934788,44.8206293 376.999569,53.9686519 L377,65.8 L374,67.494898 L344,62.6023636 L343.418118,56.4875539 Z"/>
            <path fill="#E4E0DE"
                  d="M341.020665,54.0266118 C341.006943,53.7593597 341,53.4861197 341,53.2067574 C341,44.0940591 348.387302,37.3108167 357.5,38.0559616 C366.572516,38.7978207 373.934817,46.722132 373.99957,55.7846836 L374,67.494898 L341,64.33 L341,54.0255102 L341.020665,54.0266118 Z"/>
            <path fill="#E4E0DE"
                  d="M121.478598,39.7148782 L191.767928,185.74771 L179.710082,184.648068 L108.731641,38.5523913 L0,28.6363694 L0,23.0514637 L108.246377,32.9232309 C110.343689,31.2528647 111.940659,30.4676863 113.037287,30.5676957 C114.133915,30.6677051 115.730886,31.744162 117.828198,33.7970662 L237.061911,44.6708478 C239.159223,43.0004815 240.756193,42.2153032 241.852821,42.3153126 C242.949449,42.415322 244.54642,43.4917789 246.643732,45.5446831 L428,62.0838682 L428,67.6687738 L250.294132,51.4624951 L320.583462,197.495327 L308.525616,196.395685 L237.547175,50.3000082 L121.478598,39.7148782 Z"/>
            <g transform="rotate(-42 161.487 -396.932)">
                <path fill="url(#empty-barn-a)"
                      d="M9.09494702e-13,1.65810811 C0.975683891,0.552702703 2.47568389,3.34868546e-13 4.5,3.33955086e-13 C6.52431611,3.33955086e-13 8.02431611,0.552702703 9,1.65810811 L9,33.1621622 L9.09494702e-13,33.1621622 L9.09494702e-13,1.65810811 Z"/>
                <rect width="4.5" height="18.838" x="2.25" y="33.162" fill="url(#empty-barn-b)"/>
            </g>
            <path fill="#F1F0EF"
                  d="M337.501574,53.1897822 L378.501574,53.1897822 C379.168241,53.8303713 379.501574,55.1930675 379.501574,57.2778707 C379.501574,59.362674 379.168241,60.7253702 378.501574,61.3659593 L337.501574,61.3659593 C336.834907,60.421191 336.501574,59.0584949 336.501574,57.2778707 C336.501574,55.570451 336.857006,52.3998409 338.037913,51.0413636 C338.061027,51.0147732 337.882248,51.7309127 337.501574,53.1897822 Z"
                  transform="rotate(49 358.002 56.203)"/>
            <path fill="#F7F6F6"
                  d="M363.92701,68.7791312 L417.322359,68.7791312 C418.190576,69.4197203 418.624684,70.7824165 418.624684,72.8672198 C418.624684,74.952023 418.190576,76.3147192 417.322359,76.9553083 L363.92701,76.9553083 C363.058793,76.0105401 362.624684,74.6478439 362.624684,72.8672198 C362.624684,71.0865956 363.058793,69.7238994 363.92701,68.7791312 Z"
                  transform="rotate(9 390.625 72.867)"/>
            <circle cx="367.5" cy="69.5" r="2.5" fill="#D2CCC8"/>
            <circle cx="356.5" cy="56.5" r="2.5" fill="#D2CCC8"/>
            <circle cx="413.5" cy="76.5" r="2.5" fill="#D2CCC8"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>