<template>
  <!-- eslint-disable -->
<svg class="milk crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd" transform="translate(0 30)">
        <ellipse cx="256" cy="304" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(221)">
            <polygon fill="#EAE8E5" points="88 96.983 188 59.3 188 287.3 88 324.983"/>
            <polygon fill="#F1F0EF" points="0 66.28 88 96.983 88 324.983 0 294.28"/>
            <path fill="#A8C8E5"
                  d="M88,248.622649 C91.3333333,230.40755 102,218.633333 120,213.3 C132.473555,209.604132 147.998645,215.703111 164.14367,209.704884 C172.04789,206.768295 180,199.966667 188,189.3 L188,288.421042 L88,324.14132 L88,248.622649 Z"/>
            <path fill="#CEE0F1"
                  d="M0,202.3 C5.33333333,208.966667 11.6666667,213.633333 19,216.3 C32.2537701,221.119553 34,220.3 53,221.3 C67.6666667,221.3 79.3333333,230.315892 88,248.347676 L88,324.14132 L0,298.31904 L0,202.3 Z"/>
            <polygon fill="#E4E0DE" points="52 40.3 129 16.3 188 59.3 88 97.3"/>
            <polygon fill="#EAE8E5" points="63 57.3 88 97.3 0 66.3"/>
            <polygon fill="#D2CCC8" points="52 40.3 63 57.3 0 66.3"/>
            <polygon fill="#E4E0DE" points="50.6 23 126.6 0 129.6 0 127.6 17.137 52.6 41 50.6 41"/>
            <polygon fill="#F1F0EF" points="52.5 24 129.5 0 129.5 17.137 52.5 41"/>
            <ellipse cx="116.5" cy="51.3" fill="#BCB4B1" rx="17.5" ry="14"/>
            <path fill="#D2CCC8"
                  d="M116.5,63.3 C125.060414,63.3 132,57.927417 132,51.3 C132,49.7634106 132,45.7634106 132,39.3 C125.388848,39.3 120.222181,39.3 116.5,39.3 C114.259464,39.3 109.759464,39.3 103,39.3 C101.666667,44.6163305 101,48.6163305 101,51.3 C101,57.927417 107.939586,63.3 116.5,63.3 Z"/>
            <ellipse cx="117.5" cy="41.3" fill="#BCB4B1" rx="14.5" ry="11"/>
        </g>
        <g transform="translate(104 120.3)">
            <path fill="#EAF2FA"
                  d="M134.076513,16.468392 L115.056776,193.589379 C99.693601,200.563769 84.419773,204.050965 69.2352915,204.050965 C54.05081,204.050965 37.6250762,200.245517 19.95809,192.634622 L0.233918132,16.468392 L0.250089736,16.4681119 C1.04032949,25.0399877 30.6922037,31.9313353 67.1552154,31.9313353 C103.618227,31.9313353 133.270101,25.0399877 134.060341,16.4681119 L134.076513,16.468392 Z"
                  opacity=".865"/>
            <path fill="#DCEAF6"
                  d="M67.1552154,0.302587642 C104.114827,0.302587642 134.076513,7.38292398 134.076513,16.1169615 C134.076513,24.850999 104.114827,31.9313353 67.1552154,31.9313353 C30.1956035,31.9313353 0.233918132,24.850999 0.233918132,16.1169615 C0.233918132,7.38292398 30.1956035,0.302587642 67.1552154,0.302587642 Z M67.5074328,3.11403188 C32.2985393,3.11403188 3.75609167,8.93564176 3.75609167,16.1169615 C3.75609167,23.2982812 32.2985393,29.1198911 67.5074328,29.1198911 C102.716326,29.1198911 131.258774,23.2982812 131.258774,16.1169615 C131.258774,8.93564176 102.716326,3.11403188 67.5074328,3.11403188 Z"
                  opacity=".939"/>
            <ellipse cx="67.507" cy="16.117" fill="#DCEAF6" opacity=".386" rx="63.751" ry="13.003"/>
            <path fill="#FFF"
                  d="M124.145717,56.5814554 L111.936925,190.075074 C99.9281311,195.229388 85.6942534,197.806545 69.2352915,197.806545 C52.7763296,197.806545 37.7386854,195.229388 24.1223589,190.075074 L10.8761982,56.6014971 C12.4143288,60.8696697 37.1807744,64.262944 67.5074328,64.262944 C97.8815433,64.262944 122.678027,60.8590425 124.145717,56.5814554 Z"/>
            <ellipse cx="67.507" cy="56.18" fill="#F7F6F6" rx="56.707" ry="8.083"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>