<template>
  <!-- eslint-disable -->
<svg class="radio-tower crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <defs>
        <radialGradient id="transmission-tower-a" cx="50%" cy="50%" r="66.651%" fx="50%" fy="50%"
                        gradientTransform="matrix(0 1 -.99115 0 .996 0)">
            <stop offset="0%" stop-color="#FFF2DE"/>
            <stop offset="100%" stop-color="#FFF2DE" stop-opacity="0"/>
        </radialGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <rect width="512" height="385" fill="#FFF"/>
        <g transform="translate(0 35)">
            <ellipse cx="256" cy="300" fill="#F7F6F6" rx="256" ry="50"/>
            <g transform="translate(171.555)">
                <g transform="translate(0 65)">
                    <path fill="#E4E0DE"
                          d="M151.453641,196.73778 C152.372784,197.672963 153.127515,199.240718 153.717836,201.441046 C154.308155,203.641375 154.438904,205.374112 154.110081,206.639258 L154.110081,206.639258 L86.5516184,224.501151 L86.1990803,225.818354 L83.8856184,225.206151 L81.5720733,225.818354 L81.2186184,224.501151 L13.6610728,206.639258 C13.3322499,205.374112 13.4629981,203.641375 14.0533174,201.441046 C14.6436381,199.240718 15.39837,197.672963 16.3175128,196.73778 L83.8856184,214.602151 Z M130.474424,135.770953 C131.213001,136.754519 131.877348,138.346493 132.467468,140.546875 C133.057587,142.747258 133.278417,144.455857 133.129961,145.672674 L133.129961,145.672674 L86.5506184,157.920151 L86.1999842,159.230406 L83.8856184,158.621151 L81.5711694,159.230406 L81.2196184,157.920151 L34.641193,145.672674 C34.4927361,144.455857 34.713567,142.747258 35.3036855,140.546875 C35.8938052,138.346493 36.5581531,136.754519 37.2967291,135.770953 L83.8856184,148.021151 Z M111.40241,74.287151 C111.976834,75.3147008 112.559015,76.9286914 113.148952,79.1291226 C113.738888,81.329554 114.041612,83.0162104 114.057124,84.1890918 L114.057124,84.1890918 L86.5476184,91.342151 L86.200806,92.6364929 L83.8846184,92.034151 L81.5703477,92.6364929 L81.2226184,91.342151 L53.7140296,84.1890918 C53.7295418,83.0162104 54.0322659,81.329554 54.6222021,79.1291226 C55.2121389,76.9286914 55.7943196,75.3147008 56.368744,74.287151 L83.8856184,81.442151 Z"/>
                    <path fill="#BCB4B1"
                          d="M88.9457764,-2.84217094e-14 L168,230.353827 C167.603404,232.707313 166.328474,234.256005 164.17521,234.999902 C162.021945,235.743794 160.066977,235.310945 158.310309,233.701354 L158.310309,233.701354 L88.445,30.121 L88.4450743,257.837678 C87.3339632,259.909865 85.6672965,260.945958 83.4450743,260.945958 C81.2228521,260.945958 79.5561854,259.909865 78.4450743,257.837678 L78.445,33.356 L9.68969122,233.701354 C7.93302253,235.310945 5.97805541,235.743794 3.82478985,234.999902 C1.67152598,234.256005 0.396596027,232.707313 0,230.353827 L79.0542236,-2.84217094e-14 L84,1.708 L88.9457764,-2.84217094e-14 Z"/>
                </g>
                <ellipse cx="83.945" cy="56" fill="url(#transmission-tower-a)" rx="56.5" ry="56"/>
                <ellipse cx="83.945" cy="56.5" stroke="#FF9600" stroke-width="8" opacity=".503" rx="32.5" ry="32"/>
                <circle cx="83.945" cy="56.5" r="15.5" fill="#FF9600"/>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>