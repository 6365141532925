<template>
  <!-- eslint-disable -->
<svg class="tasks-complete crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 385">
    <g fill="none" fill-rule="evenodd" transform="translate(0 32)">
        <ellipse cx="256" cy="50.591" fill="#F7F6F6" rx="256" ry="50" transform="translate(0 251.409)"/>
        <g transform="translate(105.535 14.964)">
            <ellipse cx="60.757" cy="60.622" fill="#FEDB55" rx="60.757" ry="60.622"/>
            <ellipse cx="60.757" cy="60.622" fill="#F7E49A" rx="54.594" ry="54.472"/>
            <path fill="#FFF2DE"
                  d="M64.620291,12.900266 C66.1638899,11.7606019 67.5977597,11.9402959 69.2191133,12.1728202 C73.6825112,12.8129327 76.5797782,13.6596452 79.7508834,15.0826033 C83.1388586,16.6028767 86.1925576,18.1904509 89.837945,20.9021694 C90.8440397,21.6505797 92.0290086,22.3310908 91.9994582,23.8119525 C91.979758,24.7991935 91.376448,25.8662562 90.1895284,27.0131403 L68.222813,49.2725542 C67.2621404,49.7575181 66.3014679,50 65.3407954,50 C64.3801228,50 63.6596184,49.0300723 63.1792822,47.0902169 L63,16.5374948 C63.051128,14.8724516 63.591225,13.660042 64.620291,12.900266 Z"/>
            <g fill="#FFF2DE" transform="rotate(-89 51.92 40.875)">
                <path d="M34.620291,0.900265981 C36.1638899,-0.239398055 37.5977597,-0.0597041282 39.2191133,0.172820216 C43.6825112,0.81293268 46.5797782,1.65964519 49.7508834,3.08260328 C53.1388586,4.60287665 56.1925576,6.19045094 59.837945,8.9021694 C60.8440397,9.65057969 62.0290086,10.3310908 61.9994582,11.8119525 C61.979758,12.7991935 61.376448,13.8662562 60.1895284,15.0131403 L38.222813,37.2725542 C37.2621404,37.7575181 36.3014679,38 35.3407954,38 C34.3801228,38 33.6596184,37.0300723 33.1792822,35.0902169 L33,4.53749481 C33.051128,2.87245161 33.591225,1.660042 34.620291,0.900265981 Z"/>
                <path d="M36.620291,60.900266 C38.1638899,59.7606019 39.5977597,59.9402959 41.2191133,60.1728202 C45.6825112,60.8129327 48.5797782,61.6596452 51.7508834,63.0826033 C55.1388586,64.6028767 58.1925576,66.1904509 61.837945,68.9021694 C62.8440397,69.6505797 64.0290086,70.3310908 63.9994582,71.8119525 C63.979758,72.7991935 63.376448,73.8662562 62.1895284,75.0131403 L40.222813,97.2725542 C39.2621404,97.7575181 38.3014679,98 37.3407954,98 C36.3801228,98 35.6596184,97.0300723 35.1792822,95.0902169 L35,64.5374948 C35.051128,62.8724516 35.591225,61.660042 36.620291,60.900266 Z"
                      transform="matrix(1 0 0 -1 0 158)"/>
                <path d="M1.62029096,62.900266 C3.16388989,61.7606019 4.5977597,61.9402959 6.21911332,62.1728202 C10.6825112,62.8129327 13.5797782,63.6596452 16.7508834,65.0826033 C20.1388586,66.6028767 23.1925576,68.1904509 26.837945,70.9021694 C27.8440397,71.6505797 29.0290086,72.3310908 28.9994582,73.8119525 C28.979758,74.7991935 28.376448,75.8662562 27.1895284,77.0131403 L5.22281297,99.2725542 C4.26214044,99.7575181 3.3014679,100 2.34079537,100 C1.38012283,100 0.659618429,99.0300723 0.179282162,97.0902169 L0,66.5374948 C0.0511280293,64.8724516 0.591225018,63.660042 1.62029096,62.900266 Z"
                      transform="rotate(180 14.5 81)"/>
                <path d="M1.62029096,0.900265981 C3.16388989,-0.239398055 4.5977597,-0.0597041282 6.21911332,0.172820216 C10.6825112,0.81293268 13.5797782,1.65964519 16.7508834,3.08260328 C20.1388586,4.60287665 23.1925576,6.19045094 26.837945,8.9021694 C27.8440397,9.65057969 29.0290086,10.3310908 28.9994582,11.8119525 C28.979758,12.7991935 28.376448,13.8662562 27.1895284,15.0131403 L5.22281297,37.2725542 C4.26214044,37.7575181 3.3014679,38 2.34079537,38 C1.38012283,38 0.659618429,37.0300723 0.179282162,35.0902169 L0,4.53749481 C0.0511280293,2.87245161 0.591225018,1.660042 1.62029096,0.900265981 Z"
                      transform="matrix(-1 0 0 1 29 0)"/>
            </g>
            <path fill="#FFF2DE"
                  d="M64.620291,72.900266 C66.1638899,71.7606019 67.5977597,71.9402959 69.2191133,72.1728202 C73.6825112,72.8129327 76.5797782,73.6596452 79.7508834,75.0826033 C83.1388586,76.6028767 86.1925576,78.1904509 89.837945,80.9021694 C90.8440397,81.6505797 92.0290086,82.3310908 91.9994582,83.8119525 C91.979758,84.7991935 91.376448,85.8662562 90.1895284,87.0131403 L68.222813,109.272554 C67.2621404,109.757518 66.3014679,110 65.3407954,110 C64.3801228,110 63.6596184,109.030072 63.1792822,107.090217 L63,76.5374948 C63.051128,74.8724516 63.591225,73.660042 64.620291,72.900266 Z"
                  transform="matrix(1 0 0 -1 0 182)"/>
            <path fill="#FFF2DE"
                  d="M29.620291,72.900266 C31.1638899,71.7606019 32.5977597,71.9402959 34.2191133,72.1728202 C38.6825112,72.8129327 41.5797782,73.6596452 44.7508834,75.0826033 C48.1388586,76.6028767 51.1925576,78.1904509 54.837945,80.9021694 C55.8440397,81.6505797 57.0290086,82.3310908 56.9994582,83.8119525 C56.979758,84.7991935 56.376448,85.8662562 55.1895284,87.0131403 L33.222813,109.272554 C32.2621404,109.757518 31.3014679,110 30.3407954,110 C29.3801228,110 28.6596184,109.030072 28.1792822,107.090217 L28,76.5374948 C28.051128,74.8724516 28.591225,73.660042 29.620291,72.900266 Z"
                  transform="rotate(180 42.5 91)"/>
            <path fill="#FFF2DE"
                  d="M29.620291,12.900266 C31.1638899,11.7606019 32.5977597,11.9402959 34.2191133,12.1728202 C38.6825112,12.8129327 41.5797782,13.6596452 44.7508834,15.0826033 C48.1388586,16.6028767 51.1925576,18.1904509 54.837945,20.9021694 C55.8440397,21.6505797 57.0290086,22.3310908 56.9994582,23.8119525 C56.979758,24.7991935 56.376448,25.8662562 55.1895284,27.0131403 L33.222813,49.2725542 C32.2621404,49.7575181 31.3014679,50 30.3407954,50 C29.3801228,50 28.6596184,49.0300723 28.1792822,47.0902169 L28,16.5374948 C28.051128,14.8724516 28.591225,13.660042 29.620291,12.900266 Z"
                  transform="matrix(-1 0 0 1 85 0)"/>
        </g>
        <path fill="#EAE8E5"
              d="M166.312644,75.5854897 C167.301219,86.3001718 204.36575,94.9141685 249.944018,94.9141685 C295.522286,94.9141685 332.586817,86.3001718 333.575392,75.5854897 L333.595638,75.5854897 L309.820967,296.98672 C290.616999,305.704708 271.524715,310.063702 252.544113,310.063702 C233.563512,310.063702 213.031345,305.306893 190.947612,295.793274 L166.292398,75.5854897 L166.312644,75.5854897 Z"
              opacity=".865"/>
        <path fill="#E4E0DE"
              d="M224.952782,59.7377897 C224.643624,58.5957801 224.301861,57.4671289 223.928551,56.3528919 C232.116882,55.720301 240.861408,55.3782345 249.944018,55.3782345 C296.143532,55.3782345 333.595638,64.2286548 333.595638,75.1462015 C333.595638,86.0637482 296.143532,94.9141685 249.944018,94.9141685 C203.744504,94.9141685 166.292398,86.0637482 166.292398,75.1462015 C166.292398,74.9993929 166.29917,74.852958 166.312644,74.7069134 L170.653579,74.7069134 L170.726339,74.6867553 C170.705568,74.8393874 170.695115,74.9925455 170.695115,75.1462015 C170.695115,84.1228511 206.373174,91.3998633 250.38429,91.3998633 C294.395406,91.3998633 330.073465,84.1228511 330.073465,75.1462015 C330.073465,66.169552 294.395406,58.8925398 250.38429,58.8925398 C241.491733,58.8925398 232.939376,59.1896254 224.952782,59.7377897 Z"
              opacity=".939"/>
        <path fill="#F7F6F6"
              d="M225.179563,90.5701008 C226.400834,85.7776007 227.04989,80.7571735 227.04989,75.5854897 C227.04989,70.1024495 226.32033,64.7894267 224.952782,59.7377897 C232.939376,59.1896254 241.491733,58.8925398 250.38429,58.8925398 C294.395406,58.8925398 330.073465,66.169552 330.073465,75.1462015 C330.073465,84.1228511 294.395406,91.3998633 250.38429,91.3998633 C241.575846,91.3998633 233.101196,91.1083713 225.179563,90.5701008 Z"
              opacity=".741"/>
        <path fill="#A29894"
              d="M292.589197,126.727273 L222.204812,284.460039 C219.493437,285.045756 216.99926,284.828435 214.722283,283.808075 C212.445306,282.787716 210.9174,281.218291 210.138566,279.099801 L278.131077,126.727273 L292.589197,126.727273 Z"/>
        <path fill="#DCEAF6"
              d="M179.566342,124.785763 C179.546519,124.851814 179.531144,124.918028 179.52026,124.984401 L179.500548,124.785763 L179.566342,124.785763 Z M321.202238,124.785763 L321.268031,124.785763 L321.249307,124.990501 C321.238373,124.922085 321.222668,124.853837 321.202238,124.785763 Z M321.181903,125.72752 L305.921154,292.593838 C290.910162,299.036731 273.117815,302.258178 252.544113,302.258178 C231.970411,302.258178 213.173356,299.036731 196.152948,292.593838 L179.596863,125.756333 C181.535175,131.089451 212.486537,135.328679 250.38429,135.328679 C288.350267,135.328679 319.344821,131.074174 321.181903,125.72752 Z"
              opacity=".497"/>
        <ellipse cx="250.384" cy="125.225" fill="#CEE0F1" opacity=".497" rx="70.884" ry="10.104"/>
        <path fill="#A29894"
              d="M335,0 L414,0 L414,12 L343.874092,12 L292.641681,126.727273 L278.305768,126.727273 L335,8.114176e-12 Z"/>
        <ellipse cx="414" cy="6" fill="#7F746F" rx="2" ry="6"/>
    </g>
</svg>
<!-- eslint-enable -->
</template>