<template>
  <!-- eslint-disable -->
<svg class="favicon crv-svg-icon"  id="Laag_1" width="94px" height="30px" viewBox="0 0 94 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>CRV-logo2020</title>
        <defs>
        <polygon id="path-1" points="0.0439031992 0.0285210918 11.7987928 0.0285210918 11.7987928 8.3962544 0.0439031992 8.3962544"></polygon>
        </defs>
        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Navigation-/--Footer" transform="translate(-48.000000, -18.000000)">
            <g id="CRV-logo2020" transform="translate(48.000000, 18.000000)">
            <path d="M0.36718403,18.7522993 C0.36718403,12.0999756 5.5108911,8.01995586 11.4426815,8.01995586 C14.253662,8.01995586 16.9637678,8.77381382 18.6777364,10.0761778 L18.6777364,14.8091808 C17.0313178,13.4032402 14.8363933,12.4089043 12.3685668,12.4089043 C8.90550359,12.4089043 6.26564973,14.6371535 6.26564973,18.7522993 C6.26564973,22.8323191 8.90550359,25.0956943 12.3685668,25.0956943 C14.8363933,25.0956943 17.0313178,24.099557 18.6777364,22.6954178 L18.6777364,27.4266195 C16.9637678,28.7298841 14.253662,29.4846428 11.4426815,29.4846428 C5.5108911,29.4846428 0.36718403,25.4037223 0.36718403,18.7522993" id="Fill-1" fill="#004685"></path>
            <path d="M40.483776,29.0387227 L34.4141836,29.0387227 L29.0308992,21.1173598 L27.5222826,21.1173598 L27.5222826,29.0387227 L21.9669709,29.0387227 L21.9669709,8.4647951 L31.3275991,8.4647951 C36.3335042,8.4647951 38.4951041,11.4829289 38.4951041,14.8090908 C38.4951041,17.5516206 37.0198122,19.7807705 34.4826343,20.5688538 L40.483776,29.0387227 Z M29.9225592,17.585846 C32.0481324,17.585846 33.008243,16.6257354 33.008243,14.8766408 C33.008243,13.0933209 32.0481324,12.1341109 29.9225592,12.1341109 L27.5222826,12.1341109 L27.5222826,17.585846 L29.9225592,17.585846 Z" id="Fill-3" fill="#98002E"></path>
            <polygon id="Fill-5" fill="#004685" points="50.2209729 18.5025445 54.6747693 8.4420983 60.605659 8.4420983 50.4272255 29.0349399 49.5427709 29.0349399 39.3337148 8.4420983 45.9617204 8.4420983"></polygon>
            <path d="M81.9483056,3.13492024 C81.9537096,3.14212557 83.1831195,4.81016015 84.8808761,7.11496604 C86.3084327,6.01885476 87.247828,4.15537552 87.247828,2.0406103 L87.247828,0.617557035 C87.247828,0.617557035 84.3774035,2.06312696 81.7510596,2.89083959 C81.8168083,2.97280025 81.8834576,3.04935691 81.9483056,3.13492024" id="Fill-7" fill="#004685"></path>
            <path d="M93.4907206,21.8360918 C92.514386,27.6715108 87.7327469,28.8982187 84.8289977,29.0135041 L56.1625805,29.0135041 L84.2588757,21.7973631 L76.7049848,5.45386659 C73.5130223,-1.04354241 67.5659206,2.24749342 67.5659206,2.24749342 C74.6046302,-2.24683302 78.7522,1.9052401 80.5130033,4.21815198 C80.5130033,4.21815198 93.5051193,21.8550057 93.4907206,21.8360918" id="Fill-9" fill="#98002E"></path>
            <g id="Group-13" transform="translate(61.757552, 0.121860)">
                <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
                </mask>
                <g id="Clip-12"></g>
                <path d="M8.19972894,4.31173127 C10.0848242,5.25923256 11.0422328,6.34633717 11.7987928,8.3962544 L11.1286968,8.3962544 L4.42143247,8.3962544 C1.55821328,8.00536508 -0.337689956,4.68640859 0.109040688,1.41068409 L0.298180678,0.0281608251 C0.298180678,0.0281608251 7.40353965,3.91183529 8.19972894,4.31173127" id="Fill-11" fill="#004685" mask="url(#mask-2)"></path>
            </g>
            </g>
        </g>
        </g>
    </svg>
<!-- eslint-enable -->
</template>