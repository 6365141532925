<template>
  <!-- eslint-disable -->
<svg class="messages-sent crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <polygon id="verzonden-berichten-b" points="432.8 177.627 309.771 238 224.729 206.935"/>
        <filter id="verzonden-berichten-a" width="138.8%" height="185.8%" x="-17.5%" y="-45.5%"
                filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="2" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="6"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0606317935 0"/>
        </filter>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 113)">
        <ellipse cx="256" cy="222" fill="#F7F6F6" rx="256" ry="50"/>
        <use fill="#000" filter="url(#verzonden-berichten-a)" xlink:href="#verzonden-berichten-b"/>
        <polygon fill="#F1F0EF" points="190.167 29.719 474 0 249.297 51.42"/>
        <polygon fill="#D2CCC8" points="249.297 51.42 472.157 0 258.659 100.044"/>
        <polygon fill="#E4E0DE" points="265.681 60.769 310.154 76.886 258.659 100.044"/>
        <polygon fill="#F1F0EF" points="474 0 331.224 101.282 265.681 60.769"/>
        <path stroke="#BDD6ED" stroke-dasharray="10 18" stroke-linecap="round" stroke-width="6"
              d="M230,106.303163 C157.263577,133.292768 109.589147,145.133891 86.9767083,141.826531 C11.5947763,130.800953 4.8594838,65.210189 68.9491314,33 C208.340019,-37.0551025 123.607651,149.959283 0.683533006,189.304827"/>
    </g>
</svg>
<!-- eslint-enable -->
</template>