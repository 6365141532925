<template>
  <!-- eslint-disable -->
<svg class="death-crv crv-svg-icon"  xmlns="http://www.w3.org/2000/svg"
            width="331"
            height="515"
            viewBox="0 0 331 515"
        >
            <path fill-rule="evenodd" d="M138,131 L138,14 C138,6.2680135 144.268014,1.42034288e-15 152,0 L179,0 C186.731986,-1.42034288e-15 193,6.2680135 193,14 L193,131 L317,131 C324.731986,131 331,137.268014 331,145 L331,172 C331,179.731986 324.731986,186 317,186 L193,186 L193,501 C193,508.731986 186.731986,515 179,515 L152,515 C144.268014,515 138,508.731986 138,501 L138,186 L14,186 C6.2680135,186 -8.29461588e-16,179.731986 -1.77635684e-15,172 L-1.77635684e-15,145 C-2.72325209e-15,137.268014 6.2680135,131 14,131 L138,131 Z" />
        </svg>
<!-- eslint-enable -->
</template>