<script>
export default {
    name: 'CrvVerticalStepper',

    props: {
        currentIndexes: {
            type: Array,
            default: () => [1, 1],
            note: 'Sets the currentItemGroupIndex and currentItemIndex'
        },

        items: {
            type: Array,
            note: `Provide an array of objects with the following structure: 
                    StepperItem {
                        value?: number,
                        title?: string,
                        subtitle?: string,
                        icon?: string,
                        color?: string,
                        editable?: boolean,
                        error?: boolean,
                        complete?: boolean,
                        level: number,
                        items?: StepperItem[],
                    }          
            `,
            default: () => [],
        },

        width: {
            type: String,
            default: 'auto',
            note: 'Sets the width of the vertical-stepper'
        },
    },

    emits: [
        'clickItemGroup',
        'clickItem',
    ],

    methods: {
        handleClickItemGroup(index) {
            this.$emit('clickItemGroup', index)
        },

        handleClickItem(index) {
            this.$emit('clickItem', index)
        },

        getSelectedItemClass(item) {
            if (this.currentIndexes[0] === item.value) {
                return 'selected'
            }
        },
    },
}
</script>


<template>
  <v-container>
    <v-stepper
      :model-value="currentIndexes[0]"
      elevation="0"
      flat
      :width="width"
      @update:model-value="handleClickItemGroup"
    >
      <div
        v-for="itemGroup in items"
        :key="itemGroup.value"
      >
        <v-stepper-header>
          <v-stepper-item 
            class="itemGroup"
            :title="itemGroup.title"
            :subtitle="itemGroup.subtitle"
            :value="itemGroup.value"
            :editable="itemGroup.editable"
            :edit-icon="itemGroup.icon"
            :error="itemGroup.error"
            :icon="itemGroup.icon"
            :color="itemGroup.color"
            :selected-class="getSelectedItemClass(itemGroup)"
          />
        </v-stepper-header>

        <v-stepper-window
          v-if="currentIndexes[0] === itemGroup.value"
          direction="vertical"
          reverse
        >
          <v-stepper-window-item :value="itemGroup.value">
            <v-stepper 
              :model-value="currentIndexes[1]"
              @update:model-value="handleClickItem"
            >
              <v-stepper-header
                v-for="item in itemGroup.items"
                :key="item.value"
              >
                <v-stepper-item 
                  class="item"
                  :title="item.title"
                  :subtitle="item.subtitle"
                  :value="item.value"
                  :editable="item.editable"
                  :edit-icon="item.icon"
                  :error="item.error"
                  :icon="item.icon"
                  :color="item.color"
                  :complete="item.complete"
                  :ripple="false"
                  selected-class="selected"
                >
                  <template
                    v-if="item.label && !item.complete"
                    #icon
                  >
                    {{ item.label }}
                  </template>
                </v-stepper-item>
              </v-stepper-header>
            </v-stepper>
          </v-stepper-window-item>
        </v-stepper-window>
      </div>
    </v-stepper>
  </v-container>
</template>


<style lang="scss" scoped>
@import '@v3c/assets/variables.scss';

.v-stepper-header {
    box-shadow: none;
    overflow-x: hidden;

    button {
      padding: 8px;
    }
}

.v-stepper-window {
    margin: 8px;
}

.v-stepper-item.item {
    border-left-style: solid;
    margin-left: 10px;
    padding-left: 16px;
}

:deep(.v-stepper-item) {
    div.v-stepper-item__avatar {
        color: $base-gray-100;
        background-color: $base-gray-20;
    }
}

:deep(.selected) {
    color: $base-blue-80;
    
    div.v-stepper-item__avatar {
        color: $base-white !important;
        background-color: $base-blue-80;
    }
}

</style>