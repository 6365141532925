<template>
  <v-btn
    color="base-white"
    rounded
    icon
  >
    {{ modelValue.toUpperCase() }}
    <v-tooltip
      v-model="showTooltip"
      activator="parent"
      location="bottom"
    >
      {{ tooltipText }}
    </v-tooltip>
    <v-menu
      v-model="showMenu"
      activator="parent"
      width="230"
      offset="18"
    >
      <v-card class="px-2">
        <v-list
          class="rounded-lg"
          select-strategy="classic"
          :selected="[modelValue]"
        >
          <v-list-item
            v-for="locale in locales"
            :key="locale"
            color="base-blue-70"
            link
            rounded="lg"
            :value="locale"
            :class="getLocaleClasses(locale)"
            :title="getTitle(locale)"
            :append-icon="getAppendIcon(locale)"
            @click="handleClickSelect(locale)"
          />
        </v-list>
      </v-card>
    </v-menu>    
  </v-btn>
</template>

<script>
import { mdiCheck } from '@mdi/js'
import langmap from 'langmap'


export default {
    name: 'CrvAppLocaleSwitcher',

    props: {
        modelValue: {
            type: String,
            default: 'nl', // Needs to be a valid locale from https://www.npmjs.com/package/langmap
            validator: (locale) => {
                if (!langmap[locale]) {
                    console.error(`The locale code ${locale} is not supported`)
                    return false
                }
                return true
            },
        },

        tooltipText: {
            type: String,
            default: '',
        },

        locales: {
            // locales need to be present in this list https://www.npmjs.com/package/langmap for native name
            // each locale code needs to be supported by your local i18n module
            type: Array,
            default: () => [],
            validator: (locales) => {
                const unsupportedLocales = locales.filter(locale => !langmap[locale])
                if (unsupportedLocales.length > 0) {
                    console.error('The following locale codes are not supported: ', unsupportedLocales)
                    return false
                }
                return true
            },
        },

        offset: {
            type: Number,
            default: 0,
        },
    },

    emits: [
        'update:modelValue', 
    ],

    data() {
        return {
            showTooltip: false,
            showMenu: false,
        }
    },

    watch: {
        showMenu() {
            if (this.showMenu) {
                this.showTooltip = false
            }
        },
    },

    methods: {
        handleClickSelect(locale) {
            this.$emit('update:modelValue', locale)
        },

        getLocaleClasses(locale) {
            return {
                'is-selected': locale === this.modelValue,
            }
        },

        getTitle(locale) {
            return `${langmap?.[locale]?.nativeName || 'Unsupported'} (${locale.toUpperCase()})`
        },

        getAppendIcon(locale) {
            if (locale === this.modelValue) {
                return mdiCheck
            }
        },
    },

    static() {
        return {
            mdiCheck
        }
    }
}
</script>
