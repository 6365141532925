<template>
  <!-- eslint-disable -->
<svg class="doctor-case crv-svg-fallback"  viewBox="0 0 512 385" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Doctorscase" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-5" transform="translate(0.000000, 104.000000)">
            <ellipse id="Oval-9" fill="#F7F6F6" cx="256" cy="231" rx="256" ry="50"></ellipse>
            <g id="Group" transform="translate(75.000000, 0.000000)">
                <g id="Group-2">
                    <path d="M28.3435239,28.6772425 L240.578366,67.7324992 C251.959026,69.8267576 260.22479,79.7436176 260.234827,91.3153608 L260.359732,235.318937 C260.371228,248.573765 249.635388,259.328246 236.38056,259.339742 C234.744584,259.341161 233.112749,259.175302 231.510514,258.84475 L19.1507681,215.033595 C7.99987007,212.733094 -5.71107767e-15,202.914328 -7.10542736e-15,191.528598 L-7.10542736e-15,52.2809209 C-8.72867617e-15,39.0260885 10.7451647,28.2809238 23.9999971,28.2809238 C25.4568673,28.2809238 26.9107115,28.4135777 28.3435239,28.6772425 Z"
                          id="Rectangle" fill="#F1F0EF"></path>
                    <path d="M234.013631,66.5070748 L24.9655172,28.2499919 L107.543815,3.59459459 C113.362287,1.70052622 119.068433,0.62791003 124.662252,0.376746018 C130.256071,0.125582006 135.353483,0.125582006 139.954488,0.376746018 L335.770423,36.8692886 L335.612968,36.915354 C343.805915,38.2219383 350.304797,41.3989131 355.10959,46.446285 C359.971841,51.5540164 362.179728,57.837923 361.733253,65.2980048 L360.651467,197.934103 C361.492747,204.564962 359.551461,210.789323 354.82761,216.607185 C350.103758,222.425048 345.313141,225.321219 340.455759,225.295699 L237.03602,259.055604 C244.393725,257.778671 250.089808,254.905572 254.124271,250.436307 C258.158734,245.967042 260.189812,241.083107 260.217507,235.784501 L260.217507,92.2660563 C260.037546,85.3427998 258.006467,79.646539 254.124271,75.177274 C250.242075,70.7080091 243.480531,67.8349102 233.839641,66.5579773 L234.013631,66.5070748 Z"
                          id="Combined-Shape" fill="#EAE8E5"></path>
                </g>
                <g id="Group-3" transform="translate(77.913907, 94.657658)">
                    <path d="M46.1490066,93.0955225 C71.6363992,98.2060793 92.2980132,81.716818 92.2980132,56.2657265 C92.2980132,30.8146351 71.6363992,6.03952412 46.1490066,0.928967335 C20.661614,-4.18158946 0,12.3076718 0,37.7587633 C0,63.2098547 20.661614,87.9849657 46.1490066,93.0955225 Z"
                          id="Oval" fill="#DB3E3E"></path>
                    <polygon id="Combined-Shape" fill="#FFFFFF"
                             points="57.5364238 38.3614925 81.5099338 43.1734354 81.5099338 67.1373993 57.5364238 62.3254564 57.5364238 86.2894204 33.5629139 81.4774775 33.5629139 57.5135135 9.58940397 52.7015706 9.58940397 28.7376066 33.5629139 33.5495495 33.5629139 9.58558559 57.5364238 14.3975285"></polygon>
                </g>
                <path d="M63.5298013,16.7747748 L255.421321,50.3243243 C275.446795,53.7151501 287.316613,56.9103453 291.030776,59.9099099 C294.744938,62.9094746 296.570119,67.466907 296.506316,73.5822072 L296.071494,224.063063 C296.490013,226.869093 295.690896,230.064288 293.674143,233.648649 C291.65739,237.233009 288.860932,240.428204 285.284768,243.234234 L293.590122,240.837838 C296.082323,238.908973 298.108122,236.512577 299.667521,233.648649 C301.226919,230.78472 302.24572,227.589525 302.723924,224.063063 L303.338215,73.0900901 C304.128613,65.9009009 301.680774,60.3093093 295.9947,56.3153153 C290.308626,52.3213213 278.265463,49.1261261 259.865213,46.7297297 L75.5165563,13.1801802 L63.5298013,16.7747748 Z"
                      id="Path-23" fill="#E4E0DE"></path>
                <g id="Group-4" transform="translate(123.463576, 3.594595)">
                    <path d="M1.42053421,18.4611278 L22.9263911,22.7213881 C37.2553856,12.3074185 50.6291137,8.04715827 63.0475755,9.94060727 C75.4660374,11.8340563 85.5665737,20.3545768 93.3491844,35.5021688 L108.77593,38.3423423 L124.662252,34.0820821 L124.662252,29.8218218 L108.77593,34.0820821 L96.0042628,31.2419086 C86.4515998,16.0943165 75.4660374,7.57379602 63.0475755,5.68034701 C50.6291137,3.78689801 37.2553856,8.04715827 22.9263911,18.4611278 L1.42053421,14.2008675 L1.42053421,18.4611278 Z"
                          id="Path-24" fill="#928782"></path>
                    <path d="M1.42053421,14.2008675 L22.9263911,18.4611278 C36.3001193,8.99388277 49.1962143,4.73362251 61.6146761,5.68034701 C74.033138,6.62707152 85.4963335,15.147592 96.0042628,31.2419086 L108.77593,34.0820821 L124.662252,29.8218218 L108.77593,26.9816483 C98.3509527,11.8340563 86.4515998,3.31353576 73.0778717,1.42008675 C59.7041435,-0.473362251 46.3304154,2.84017351 32.9566872,11.360694 L18.6276928,8.52052052 L1.42053421,14.2008675 Z"
                          id="Path-25" fill="#A29894"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>