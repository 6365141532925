<template>
  <v-app :class="$vuetify.display.name">
    <v-app-bar color="base-brand-blue">
      <crv-app-title
        :allow-click="allowClickAppTitle"
        @click="handleClickAppTitle"
      >
        <v-icon 
          v-if="shouldShowNavBackInTitle"
          :icon="mdiChevronLeft" 
          @click.stop="handleClickBack"
        />
        <slot name="app-title" />
        <v-spacer />
      </crv-app-title>
      <crv-app-account-menu
        v-if="showAccountMenu"
        :name="accountName"
        :account-label="accountLabel"
        :tooltip-text="accountMenuTooltip"
        :is-switch-customer-page="isSwitchCustomerPage"
        :allow-switch-customer="allowSwitchCustomer"
        :customer-name="customerName"
        :locations-label="locationsLabel"
        :logout-label="logoutLabel"
        :my-crv-label="myCrvLabel"
        :faq-label="faqLabel"
        :show-my-crv-and-faq="showMyCrvAndFaq"
        :is-my-crv="isMyCrv"
        :select-customer-text="selectCustomerText"
        :market-country-code="marketCountryCode"
        @logout="handleLogout"
        @switch-customer="handleSwitchCustomer"
        @click-location="handleClickLocation"
      />
      <crv-app-locale-switcher 
        v-if="showLocaleSwitcher"
        :model-value="locale"
        :tooltip-text="localeTooltip"
        :locales="locales"
        @update:model-value="handleSwitchLocale"
      />
      <crv-app-switcher 
        v-if="showAppSwitcher"
        :app-menu="appMenu"
        :market-country-code="marketCountryCode"
        :tooltip="appSwitcherTooltip" 
      />
      <!-- TODO not used right now -->
      <!-- <crv-print v-if="hasMobilePrintButton"
                class="crv-mobile-header_print-button"
                :title="printTitle"
                :print-text="printText"
                :include-header="includeHeaderPrint"
                :table-base-body-rows="printBodyRows"
                :table-base-header-rows="printHeaders"
            ></crv-print> -->
    </v-app-bar>
    <crv-app-side-menu 
      v-if="showSideMenu"
      :collapse-label="collapseLabel"
      :is-collapsed="isCollapsed"
      :side-menu="sideMenu"
      :active-menu-id="activeMenuId"
      @navigate="handleClickNavigate"
    />
    <crv-app-bottom-menu
      v-if="showBottomMenu"
      :account-name="accountName"
      :account-label="accountLabel"
      :locations-label="locationsLabel"
      :logout-label="logoutLabel"
      :customer-name="customerName"
      :customer-number="customerNumber"
      :customer-number-label="customerNumberLabel"
      :bottom-menu="bottomMenu"
      :more-menu="moreMenu"
      :more-menu-label="moreMenuLabel"
      :locations="locations"
      :allow-switch-customer="allowSwitchCustomer"
      :active-menu-id="activeMenuId"
      @navigate="handleClickNavigate"
      @logout="handleLogout"
      @switch-customer="handleSwitchCustomer"
      @click-location="handleClickLocation"
    />
    <v-main
      class="main-content"
      :class="classes"
    >
      <slot />
      <crv-app-footer v-if="showFooter" />
    </v-main>
  </v-app>
</template>


<script>
import { mdiChevronLeft } from '@mdi/js'
import CrvAppAccountMenu from '@v3c/components/CrvAppAccountMenu.vue'
import CrvAppBottomMenu from '@v3c/components/CrvAppBottomMenu.vue'
import CrvAppFooter from '@v3c/components/CrvAppFooter.vue'
import CrvAppLocaleSwitcher from '@v3c/components/CrvAppLocaleSwitcher.vue'
import CrvAppSideMenu from '@v3c/components/CrvAppSideMenu.vue'
import CrvAppSwitcher from '@v3c/components/CrvAppSwitcher.vue'
import CrvAppTitle from '@v3c/components/CrvAppTitle.vue'


export default {
    name: 'CrvAppShell',

    components: {
        CrvAppTitle,
        CrvAppAccountMenu,
        CrvAppLocaleSwitcher,
        CrvAppSwitcher,
        CrvAppSideMenu,
        CrvAppBottomMenu,
        CrvAppFooter,
    },

    props: {
        shouldShowNavBackInTitle: {
            type: Boolean,
            default: true,
        },

        allowClickAppTitle: {
            type: Boolean,
            default: true,
        },

        showAccountMenu: {
            type: Boolean,
            default: true,
        },

        allowSwitchCustomer: {
            type: Boolean,
            default: true,
        },

        accountLabel: {
            type: String,
            default: 'Ingelogd als',
        },

        accountMenuTooltip: {
            type: String,
            default: ''
        },

        accountName: {
            type: String,
            default: undefined,
        },

        locations: {
            type: Array,
            default: () => [],
        },

        locationNumber: {
            type: String,
            default: '',
        },

        customerName: {
            type: String,
            default: undefined,
        },

        customerNumber: {
            type: String,
            default: '',
        },

        customerNumberLabel: {
            type: String,
            default: '',
        },

        selectCustomerText: {
            type: String,
            default: 'Selecteer bedrijf',
        },

        isSwitchCustomerPage: {
            type: Boolean,
            default: false,
        },

        showLocaleSwitcher: {
            type: Boolean,
            default: true,
        },

        localeTooltip: {
            type: String,
            default: ''
        },

        locale: {
            type: String,
            default: 'nl',
        },

        locales: {
            type: Array,
            default: () => [],
        },

        showAppSwitcher: {
            type: Boolean,
            default: true,
        },

        appSwitcherTooltip: {
            type: String,
            default: ''
        },

        appMenu: {
            type: Object,
            default: undefined,
        },

        marketCountryCode: {
            type: String,
            required: true,
        },

        showSideMenu: {
            type: Boolean,
            default: true,
        },

        collapseLabel: {
            type: String,
            default: '',
        },

        isCollapsed: {
            type: Boolean,
            default: false,
        },

        sideMenu: {
            type: Array,
            default: () => [],
        },

        locationsLabel: {
            type: String,
            default: 'Toon gegevens van',
        },

        myCrvLabel: {
            type: String,
            default: 'Mijn CRV',
        },

        faqLabel: {
            type: String,
            default: 'Veelgestelde vragen',
        },

        logoutLabel: {
            type: String,
            default: 'Uitloggen',
        },

        showMyCrvAndFaq: {
            type: Boolean,
            default: true,
        },

        isMyCrv: {
            type: Boolean,
            default: false,
        },

        showBottomMenu: {
            type: Boolean,
            default: true,
        },

        bottomMenu: {
            type: Array,
            default: () => [],
        },

        moreMenu: {
            type: Array,
            default: () => [],
            note: 'More menu for mobile mode',
        },

        moreMenuLabel: {
            type: String,
            default: 'Meer',
        },        

        activeMenuId: {
            type: String,
            default: '',
            note: 'The id (key) of the active menu item',
        },

        showFooter: {
            type: Boolean,
            default: true,
        },
    },

    emits: [
        'click-app-title',
        'switch-locale',
        'navigate',
        'logout', 
        'switch-customer', 
        'click-location',
        'click-back',
    ],

    static() {
        return {
            mdiChevronLeft,            
        }
    },

    computed: {
        classes() {
            return {
                'has-footer': this.showFooter,
            }
        },
    },

    methods: {
        handleClickAppTitle() {
            this.$emit('click-app-title')
        },

        handleSwitchLocale(locale) {
            this.$emit('switch-locale', locale)
        },

        handleClickNavigate(item) {
            this.$emit('navigate', item)
        },

        handleLogout() {
            this.$emit('logout')
        },

        handleSwitchCustomer() {
            if (this.allowSwitchCustomer) {
                this.$emit('switch-customer')
            }
        },

        handleClickLocation(location) {
            this.$emit('click-location', location)
        },

        handleClickBack() {
            this.$emit('click-back')
        },
    },
}
</script>


<style lang="scss" scoped>
@import '@v3c/assets/variables.scss';
    .main-content {
        min-height: calc(100vh - #{$desktop-header-height + $vertical-spacing-quadruple + 1px});

        &.has-footer {
            min-height: calc(100vh - #{$desktop-header-height + $desktop-footer-height + $vertical-spacing-quadruple + 1px});
        }
    }

</style>

