<script setup lang="ts">
import { ref, watch } from 'vue'
import { refDebounced } from '@vueuse/core'


// * interface: (props and emits)
const props = withDefaults(
    defineProps<{
        modelValue?: string,
        headingText?: string,
        hint?: string,
        placeholder?: string,
        persistentHint?: boolean
        width?: string,
        hideDetails?: boolean,
        disabled?: boolean,
        readonly?: boolean,
        debounceInterval?: number,
        error: boolean,
        errorMessages?: string[],
        trim?: boolean,
    }>(),
    {
        modelValue: '',
        headingText: '',
        hint: '',
        placeholder: '',
        persistentHint: true,
        width: '100%',
        hideDetails: true,
        disabled: false,
        readonly: false,
        debounceInterval: 200,
        error: false,
        errorMessages: () => [],
        trim: false,
    },
)

const emit = defineEmits(['update:model-value', 'cleared'])


// * refs
const searchValue = ref(props.modelValue)
const debouncedSearchValue = refDebounced(searchValue, props.debounceInterval)


// * watch
watch(
    debouncedSearchValue,
    () => {
        emit('update:model-value', debouncedSearchValue.value)
    }
)


// * methods
// const handleClickInput = () => {
//     init()
// }

const handleClear = () => {
    searchValue.value = ''
    emit('cleared')
}

// Remains in case apps like to implement searching on click
// const init = () => {
//     // clears the input on click again, should this be configurable?
//     // searchValue.value = ''
//     // emit('update:model-value', searchValue.value)
// }

// defineExpose({init})
</script>


<template>
  <v-sheet
    :width="width"
  >
    <v-text-field
      v-model="searchValue"
      :model-modifiers="{ trim: props.trim }"
      autofocus
      variant="underlined"
      color="primary"
      append-inner-icon="$magnify"
      :label="headingText"
      :placeholder="placeholder"
      :hint="hint"
      :persistent-hint="persistentHint"
      :hide-details="hideDetails"
      :disabled="disabled"
      :readonly="readonly"
      :error="error"
      :error-messages="errorMessages"
      clearable
      @click:clear="handleClear"
    />
  </v-sheet>
  <!-- @click="handleClickInput" -->
</template>