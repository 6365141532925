import CrvAnimal from '@v3c/images/svg/crvAnimal.svg'
import CrvCatalogSolid from '@v3c/images/svg/crvCatalogSolid.svg'
import CrvDigiKlauw from '@v3c/images/svg/crvDigiKlauw.svg'
import CrvHerd from '@v3c/images/svg/crvHerd.svg'
import CrvHerdOptimizer from '@v3c/images/svg/crvHerdOptimizer.svg'
import CrvMineral from '@v3c/images/svg/crvMineral.svg'
import CrvMprDracht from '@v3c/images/svg/crvMprDracht.svg'
import CrvOvalert from '@v3c/images/svg/crvOvalert.svg'
import CrvPhosphatePlanner from '@v3c/images/svg/crvPhosphatePlanner.svg'
import CrvSap from '@v3c/images/svg/crvSap.svg'
import CrvShoppingCartSolid from '@v3c/images/svg/crvShoppingCartSolid.svg'
import CrvVeeManager from '@v3c/images/svg/crvVeeManager.svg'


const applications = {
    animal: {
        id: 'animal',
        iconComponent: CrvAnimal,
        label: 'Dier',
    },
    herd: {
        id: 'herd',
        iconComponent: CrvHerd,
        label: 'Bedrijf',
    },
    crvMineral: {
        id: 'crvMineral',
        iconComponent: CrvMineral,
        label: 'Mineraal',
    },
    herdOptimizerNlBe: {
        id: 'herdOptimizer',
        iconComponent: CrvHerdOptimizer,
        label: 'Fokkerij', //'HerdOptimizer'
    },
    herdOptimizer: {
        id: 'herdOptimizer',
        iconComponent: CrvHerdOptimizer,
        label: 'HerdOptimizer',
    },
    phosphatePlanner: {
        id: 'phosphatePlanner',
        iconComponent: CrvPhosphatePlanner,
        label: 'Fosfaatplanner',
    },
    crvOvalert: {
        id: 'crvOvalert',
        iconComponent: CrvOvalert,
        label: 'Ovalert',
    },
    crvSap: {
        id: 'crvSap',
        iconComponent: CrvSap,
        label: 'SAP',
    },
    MprDracht: {
        id: 'MprDracht',
        iconComponent: CrvMprDracht,
        label: 'MPR Dracht',
    },
    crvDigiKlauw: {
        id: 'crvDigiKlauw',
        iconComponent: CrvDigiKlauw,
        label: 'Digiklauw',
    },
    webshop: {
        id: 'webshop',
        iconComponent: CrvShoppingCartSolid,
        iconSize: 18,
        label: 'Webshop',
    },
    veeManager: {
        id: 'veeManager',
        iconComponent: CrvVeeManager,
        iconSize: 18,
        label: 'VeeManager',
    },
    catalog: {
        id: 'catalog',
        iconComponent: CrvCatalogSolid,
        iconSize: 18,
        label: 'Catalog',
    },
}

const populateAllEnvironments = (label, apps) => {
    const id = 'crvApplications'
    return {
        prd: [{
            id,
            label,
            apps: apps.prd,
        }],
        acc: [{
            id,
            label,
            apps: apps.acc,
        }],
        dev: [{
            id,
            label,
            apps: apps.dev,
        }],
        tst: [{
            id,
            label,
            apps: apps.tst,
        }],
        // Checks if url starts with localhost and thus uses tst-buttons.
        get loc() {
            return this.tst
        },
    }
}

const appMenu = {
    //FIXME: this entire file seems to do nothing with translations, no marketCountryCode / Locale...
    // Neither do these functions check if the app itself should remain within the selectable default app menu.
    NL: populateAllEnvironments('CRV Applicaties',
        {
            prd: [
                { ...applications.animal, url: 'https://www.crvdier.nl/' },
                { ...applications.herd, url: 'https://www.crvbedrijf.nl/analysis/' },
                { ...applications.crvMineral, url: 'https://crvmineraal.nl/' },
                { ...applications.herdOptimizerNlBe, url: 'https://www.crvfokkerij.nl/herdoptimizer/' },
                { ...applications.phosphatePlanner, url: 'https://fosfaatplanner.nl/' },
                { ...applications.crvOvalert, url: 'https://apps.crv4all.nl/navigation-ovalert/menu/activiteit-dier' },
                { ...applications.crvSap, url: 'https://apps.crv4all.nl/navigation-sap/' },
                { ...applications.MprDracht, url: 'https://apps.crv4all.nl/navigation-mprdracht/' },
                { ...applications.crvDigiKlauw, url: 'https://apps.crv4all.nl/navigation-digiklauw/' },
                { ...applications.webshop, url: 'https://shop.crv4all.nl/' },
            ],
            acc: [
                { ...applications.animal, url: 'https://acc.crvdier.nl/' },
                { ...applications.herd, url: 'https://acc.crvbedrijf.nl/analysis/' },
                { ...applications.crvMineral, url: 'https://acc.crvmineraal.nl/' },
                { ...applications.herdOptimizerNlBe, url: 'https://acc.crvfokkerij.nl/herdoptimizer/' },
                { ...applications.phosphatePlanner, url: 'https://acc.fosfaatplanner.nl/' },
                { ...applications.crvOvalert, url: 'https://apps.crv4all.nl/navigation-ovalert/menu/activiteit-dier' },
                { ...applications.crvSap, url: 'https://apps.crv4all.nl/navigation-sap/' },
                { ...applications.MprDracht, url: 'https://apps.crv4all.nl/navigation-mprdracht/' },
                { ...applications.crvDigiKlauw, url: 'https://accapps.crv4all.nl/navigation-digiklauw/' },
                { ...applications.webshop, url: 'https://webshop-nl.acc-omni.crv4all.com/nl/' },
            ],
            tst: [
                { ...applications.animal, url: 'https://tst.crvdier.nl/' },
                { ...applications.herd, url: 'https://tst.crvbedrijf.nl/analysis/' },
                { ...applications.crvMineral, url: 'https://tst.crvmineraal.nl/' },
                { ...applications.herdOptimizerNlBe, url: 'https://tst.crvfokkerij.nl/herdoptimizer/' },
                { ...applications.phosphatePlanner, url: 'https://tst.fosfaatplanner.nl/' },
                {
                    ...applications.crvOvalert,
                    url: 'https://accapps.crv4all.nl/navigation-ovalert/menu/activiteit-dier',
                },
                { ...applications.crvSap, url: 'https://testapps.crv4all.nl/navigation-sap/' },
                { ...applications.MprDracht, url: 'https://testapps.crv4all.nl/navigation-mprdracht/' },
                { ...applications.crvDigiKlauw, url: 'https://testapps.crv4all.nl/navigation-digiklauw/' },
                { ...applications.webshop, url: 'https://webshop-nl.acc-omni.crv4all.com/nl/' },
            ],
            dev: [
                { ...applications.animal, url: 'https://acc.crvdier.nl/' },
                { ...applications.herd, url: 'https://acc.crvbedrijf.nl/analysis/' },
                { ...applications.crvMineral, url: 'https://acc.crvmineraal.nl/' },
                { ...applications.herdOptimizerNlBe, url: 'https://acc.crvfokkerij.nl/herdoptimizer/' },
                { ...applications.phosphatePlanner, url: 'https://acc.fosfaatplanner.nl/' },
                {
                    ...applications.crvOvalert,
                    url: 'https://accapps.crv4all.nl/navigation-ovalert/menu/activiteit-dier',
                },
                { ...applications.crvSap, url: 'https://accapps.crv4all.nl/navigation-sap/' },
                { ...applications.MprDracht, url: 'https://apps.crv4all.nl/navigation-mprdracht/' },
                { ...applications.crvDigiKlauw, url: 'https://accapps.crv4all.nl/navigation-digiklauw/' },
                { ...applications.webshop, url: 'https://webshop-nl.acc-omni.crv4all.com/nl/' },
            ],
        }),
    BE: populateAllEnvironments('CRV Applicaties', {
        prd: [
            { ...applications.animal, url: 'https://www.crvdier.be/' },
            { ...applications.herd, url: 'https://www.crvbedrijf.be/analysis/' },
            { ...applications.herdOptimizerNlBe, url: 'https://www.crvfokkerij.be/herdoptimizer/' },
            { ...applications.crvOvalert, url: 'https://apps.crv4all.be/navigation-ovalert/menu/activiteit-dier' },
            { ...applications.crvSap, url: 'https://apps.crv4all.be/navigation-sap/' },
            { ...applications.MprDracht, url: 'https://apps.crv4all.be/navigation-mprdracht/' },
            { ...applications.webshop, url: 'https://shop.crv4all.be/' },
        ],
        acc: [
            { ...applications.animal, url: 'https://acc.crvdier.be/' },
            { ...applications.herd, url: 'https://acc.crvbedrijf.be/analysis/' },
            { ...applications.herdOptimizerNlBe, url: 'https://acc.crvfokkerij.be/herdoptimizer/' },
            { ...applications.crvOvalert, url: 'https://accapps.crv4all.be/navigation-ovalert/menu/activiteit-dier' },
            { ...applications.crvSap, url: 'https://accapps.crv4all.be/navigation-sap/' },
            { ...applications.MprDracht, url: 'https://accapps.crv4all.be/navigation-mprdracht/' },
            { ...applications.webshop, url: 'https://webshop-be.acc-omni.crv4all.com/be/' },
        ],
        tst: [
            { ...applications.animal, url: 'https://tst.crvdier.be/' },
            { ...applications.herd, url: 'https://tst.crvbedrijf.be/analysis/' },
            { ...applications.herdOptimizerNlBe, url: 'https://tst.crvfokkerij.be/herdoptimizer/' },
            { ...applications.crvOvalert, url: 'https://accapps.crv4all.be/navigation-ovalert/menu/activiteit-dier' },
            { ...applications.crvSap, url: 'https://testapps.crv4all.be/navigation-sap/' },
            { ...applications.MprDracht, url: 'https://testapps.crv4all.nl/navigation-mprdracht/' },
            { ...applications.webshop, url: 'https://webshop-be.acc-omni.crv4all.com/be/' },
        ],
        dev: [
            { ...applications.animal, url: 'https://acc.crvdier.be/' },
            { ...applications.herd, url: 'https://acc.crvbedrijf.be/analysis/' },
            { ...applications.herdOptimizerNlBe, url: 'https://acc.crvfokkerij.be/herdoptimizer/' },
            { ...applications.crvOvalert, url: 'https://accapps.crv4all.be/navigation-ovalert/menu/activiteit-dier' },
            { ...applications.crvSap, url: 'https://accapps.crv4all.be/navigation-sap/' },
            { ...applications.MprDracht, url: 'https://ontapps.crv4all.be/navigation-mprdracht/' },
            { ...applications.webshop, url: 'https://webshop-be.acc-omni.crv4all.com/be/' },
        ],
    }),
    CZ: populateAllEnvironments('CRV Aplikací', {
        prd: [
            { ...applications.veeManager, url: 'https://apps.crv4all.cz/veemanager/' },
            { ...applications.herdOptimizer, url: 'https://www.crvherdoptimizer.cz/herdoptimizer/' },
        ],
        acc: [
            { ...applications.veeManager, url: 'https://accapps.crv4all.cz/veemanager/' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.cz/herdoptimizer/' },
        ],
        tst: [
            { ...applications.veeManager, url: 'https://accapps.crv4all.cz/veemanager/' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.cz/herdoptimizer/' },
        ],
        dev: [
            { ...applications.veeManager, url: 'https://accapps.crv4all.cz/veemanager/' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.cz/herdoptimizer/' },
        ],
    }),
    DE: populateAllEnvironments('CRV Apps', {
        prd: [
            { ...applications.veeManager, url: 'https://apps.crv4all.de/veemanager/', label: 'Repromanager' },
            { ...applications.herdOptimizer, url: 'https://www.crvherdoptimizer.de/herdoptimizer/' },
        ],
        acc: [
            { ...applications.veeManager, url: 'https://accapps.crv4all.de/veemanager/', label: 'Repromanager' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.de/herdoptimizer/' },
        ],
        tst: [
            { ...applications.veeManager, url: 'https://accapps.crv4all.de/veemanager/', label: 'Repromanager' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.de/herdoptimizer/' },
        ],
        dev: [
            { ...applications.veeManager, url: 'https://accapps.crv4all.de/veemanager/', label: 'Repromanager' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.de/herdoptimizer/' },
        ],
    }),
    US: populateAllEnvironments('CRV Applications', {
        prd: [
            { ...applications.catalog, url: 'https://shop.crv4all.com/en' },
            { ...applications.herdOptimizer, url: 'https://www.crvherdoptimizer.us/herdoptimizer/' },
        ],
        acc: [
            { ...applications.catalog, url: 'https://webshop-us.acc-omni.crv4all.com/en' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.us/herdoptimizer/' },
        ],
        tst: [
            { ...applications.catalog, url: 'https://webshop-us.acc-omni.crv4all.com/en' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.us/herdoptimizer/' },
        ],
        dev: [
            { ...applications.catalog, url: 'https://webshop-us.acc-omni.crv4all.com/en' },
            { ...applications.herdOptimizer, url: 'https://acc.crvherdoptimizer.us/herdoptimizer/' },
        ],
    }),
}
export default appMenu
