<template>
  <!-- eslint-disable -->
<svg class="thunderstorm crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 385">
    <defs>
        <filter id="thunderstorm-a" width="115.5%" height="189.9%" x="-7.7%" y="-44.9%" filterUnits="objectBoundingBox">
            <feGaussianBlur in="SourceGraphic" stdDeviation="8"/>
        </filter>
        <path id="thunderstorm-b"
              d="M302.914097,36.3686955 C292.423565,36.3686955 282.674817,39.5454248 274.577716,44.9847068 C259.703164,18.1642331 231.084794,0 198.210202,0 C154.488079,0 118.278242,32.1182594 111.943917,74.0177932 C108.30168,73.1896241 104.516365,72.7387782 100.6227,72.7387782 C80.0375322,72.7387782 62.3236475,84.9643308 54.3293402,102.534835 C49.33967,100.109977 43.7346257,98.7477293 37.8128652,98.7477293 C16.9276505,98.7477293 0,115.653756 0,136.507805 C0,157.363241 16.9276505,174.269267 37.8128652,174.269267 L331.639428,200.973214 C359.714657,200.973214 399.259856,182.072368 389.754786,123.502635 C386.440923,103.082787 370.305204,85.3624624 352.795518,77.3443421 C348.226747,53.9933008 327.634634,36.3686955 302.914097,36.3686955 Z"/>
        <polygon id="thunderstorm-d"
                 points="7.421 0 0 35.066 21.981 37.041 7.421 70.131 64.082 21.735 38.297 19.941 45.233 3.22"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <rect width="512" height="385" fill="#FFF"/>
        <g transform="translate(1 41)">
            <ellipse cx="256" cy="285.46" fill="#F7F6F6" rx="256" ry="50.54"/>
            <path fill="#BCB4B1"
                  d="M206.939457,11.1381579 C219.983262,11.1381579 231.41087,12.8448393 237.751586,15.402195 C242.096678,14.2525639 247.59457,13.5657895 253.57346,13.5657895 C265.736565,13.5657895 275.909039,16.4080374 278.44451,20.2095636 C298.055904,21.2152817 313.023697,26.5611769 313.023697,33.0047494 C313.023697,38.2389002 303.140819,42.7453574 288.921703,44.7931029 C285.211535,50.7567188 268.489268,55.2578617 248.414425,55.2578617 C239.895364,55.2578617 231.978684,54.4465577 225.40326,53.0574211 C213.324061,59.9070943 190.083901,64.5460526 163.387384,64.5460526 C127.881896,64.5460526 98.4769221,56.3433794 93.3329976,45.6426693 C90.375241,45.8541752 87.3012949,45.9693166 84.1393605,45.9693166 C67.4227339,45.9693166 53.0377941,42.8470361 46.5458455,38.3597101 C42.4938769,38.9789937 37.9421805,39.3268979 33.1332879,39.3268979 C16.1730017,39.3268979 2.42654028,35.0092726 2.42654028,29.6833629 C2.42654028,24.357099 16.1730017,20.0394737 33.1332879,20.0394737 L60.5821391,20.0395184 C66.3744384,17.4081014 78.8025703,15.5888158 93.1996529,15.5888158 C105.126577,15.5888158 115.70223,16.8373741 122.273296,18.7618822 C126.947822,15.629593 140.563379,13.3634868 156.631467,13.3634868 C164.489173,13.3634868 171.760367,13.9054173 177.686312,14.8255362 C184.242459,12.5914502 194.902836,11.1381579 206.939457,11.1381579 Z"
                  filter="url(#thunderstorm-a)" opacity=".312" transform="rotate(4 -3401.17 1588.343)"/>
            <g transform="translate(65.517)">
                <mask id="thunderstorm-c" fill="#fff">
                    <use xlink:href="#thunderstorm-b"/>
                </mask>
                <use fill="#F1F0EF" xlink:href="#thunderstorm-b"/>
                <path fill="#E4E0DE"
                      d="M144.517073 93.2266015C135.883777 86.1878571 122.840068 85.1266353 113.180222 90.678282 103.521765 96.2299286 97.8875494 108.026831 99.644769 119.014985 103.107811 110.422556 109.750518 104.336831 117.625362 99.4441128 125.500206 94.5500075 135.267013 92.5329925 144.517073 93.2266015M257.933443 104.034417C257.256948 92.9255752 249.189018 82.6338045 238.55263 79.3114173 227.917632 75.9904173 215.414285 79.856594 208.521539 88.6002293 217.192341 85.3125226 226.175692 86.0602331 235.11737 88.5183835 244.059048 90.9779211 252.17004 96.7764925 257.933443 104.034417"
                      mask="url(#thunderstorm-c)"/>
                <path fill="#E4E0DE"
                      d="M119.126986 98.0430226C119.688185 64.715109 141.231836 33.1669962 170.972604 18.036609 193.97065 6.33542481 221.906968 5.28807519 246.031579 14.2578271 232.297484 5.24784586 215.871301-4.22772928e-13 198.210202-4.22772928e-13 154.488079-4.22772928e-13 118.278242 32.1182594 111.943917 74.0177932 108.30168 73.1896241 104.516365 72.7387782 100.6227 72.7387782 90.9920253 72.7387782 81.9906158 75.4147218 74.3185812 80.0619023 80.5653927 78.5831278 87.1469788 78.4998947 93.403514 79.9426015 103.881544 82.3619098 113.319132 89.0025226 119.126986 98.0430226M352.795518 77.3443421C348.226747 53.9933008 327.634634 36.3686955 302.914097 36.3686955 292.423565 36.3686955 282.674817 39.5454248 274.577716 44.9847068 274.260999 44.4145602 245.321746 69.5870188 237.389948 87.0160263 250.36698 71.9008985 266.227797 60.070703 285.671119 55.6704474 305.114441 51.2715789 328.04581 58.0661729 337.858457 75.3994624 318.770746 71.5790639 297.882753 79.3655188 284.538997 93.5179173 303.358611 76.1568835 333.531391 76.414906 355.269517 89.9541541 369.521748 98.8323496 388.812744 123.965966 391.659023 140.444729 391.996576 138.082297 392.181327 135.669925 392.181327 133.213162 392.181327 108.031237 370.305204 85.3624624 352.795518 77.3443421z"
                      mask="url(#thunderstorm-c)"/>
            </g>
            <g transform="translate(230.242 186.407)">
                <mask id="thunderstorm-e" fill="#fff">
                    <use xlink:href="#thunderstorm-d"/>
                </mask>
                <use fill="#FF9600" xlink:href="#thunderstorm-d"/>
                <path fill="#D68107"
                      d="M42.4912517,5.53877345 L34.8246445,22.5827068 L57.7709457,23.9629572 L3.27962085,70.7815663 L9.60113467,75.5414633 L74.5723969,19.3530784 L46.917771,17.7493451 C51.260198,10.1506458 53.1210461,6.35129612 52.5003152,6.35129612 C51.8795843,6.35129612 48.5432298,6.08045523 42.4912517,5.53877345 Z"
                      mask="url(#thunderstorm-e)"/>
                <polygon fill="#D68107" points="2.844 -2.826 .744 3.111 46.776 6.174 47.66 .113"
                         mask="url(#thunderstorm-e)" transform="rotate(1 24.202 1.674)"/>
            </g>
            <ellipse cx="319.09" cy="209.99" stroke="#A29894" stroke-width="2.427" rx="3.64" ry="3.641"/>
            <ellipse cx="353.062" cy="224.556" stroke="#A29894" stroke-width="2.427" rx="6.066" ry="6.069"/>
            <ellipse cx="161.365" cy="231.839" stroke="#A29894" stroke-width="2.427" rx="6.066" ry="6.069"/>
            <g stroke="#A29894" stroke-linecap="square" stroke-width="5.309" transform="rotate(5 -421.256 1217.648)">
                <polyline points="52.787 0 50.312 3.789 53.813 7.054 51.338 10.843 54.84 14.108"/>
                <polyline points="20.029 10.924 25.261 11.899 24.864 17.381 30.096 18.356 29.7 23.838"/>
                <polyline points="0 37.01 5.222 35.519 6.981 40.354 12.203 38.863 13.962 43.697"
                          transform="rotate(180 6.981 39.608)"/>
            </g>
            <path stroke="#A29894" stroke-linecap="round" stroke-width="2.213"
                  d="M319.090047,246.404605 L320.303318,252.473684" transform="matrix(-1 0 0 1 638.18 0)"/>
            <path stroke="#A29894" stroke-linecap="round" stroke-width="2.213"
                  d="M2.69473807.342497666L3.90800821 6.41157661M14.0313552 2.95166011L15.2446253 9.02073906"
                  transform="rotate(26 -357.455 364.513)"/>
            <path stroke="#A29894" stroke-linecap="round" stroke-width="2.213"
                  d="M323.336493,239.728618 L324.549763,245.797697" transform="matrix(-1 0 0 1 646.673 0)"/>
            <path stroke="#A29894" stroke-linecap="round" stroke-width="2.213"
                  d="M318.483412,237.300987 L319.696682,243.370066" transform="matrix(-1 0 0 1 636.967 0)"/>
            <path stroke="#A29894" stroke-linecap="round" stroke-width="2.213"
                  d="M396.132701,210.597039 L397.345972,216.666118" transform="matrix(-1 0 0 1 792.265 0)"/>
            <path stroke="#A29894" stroke-linecap="round" stroke-width="2.213"
                  d="M197.156398,196.03125 L198.369668,202.100329" transform="matrix(-1 0 0 1 394.313 0)"/>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>