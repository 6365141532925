<template>
  <!-- eslint-disable -->
<svg class="no-semen-stock crv-svg-fallback"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="216" height="156"
     viewBox="0 0 216 156">
    <defs>
        <path id="semen-stock-a"
              d="M47.2499972,29.3499983 L47.2499972,26.3124985 L67.162496,26.3124985 L67.162496,29.3499983 L62.4374963,29.3499983 C62.4374963,29.9091866 60.2464899,30.3624982 57.5437466,30.3624982 C54.8410032,30.3624982 52.6499969,29.9091866 52.6499969,29.3499983 L52.6499969,29.3499983 L47.2499972,29.3499983 Z"/>
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(0 -3)">
        <ellipse cx="108" cy="137.688" fill="#F7F6F6" rx="108" ry="21.094"/>
        <g transform="translate(50.625)">
            <path fill="#EAE8E5"
                  d="M57.5437466,39.4749977 C78.8860991,39.4749977 96.1874943,55.1898021 96.1874943,74.5749956 L96.1874943,74.5749956 L96.1874943,134.649992 C96.1874943,139.869083 78.8860991,144.099991 57.5437466,144.099991 C36.201394,144.099991 18.8999989,139.869083 18.8999989,134.649992 L18.8999989,134.649992 L18.8999989,74.5749956 L18.905176,73.994553 C19.2464309,54.8770774 36.4148176,39.4749977 57.5437466,39.4749977 Z"/>
            <path fill="#D2CCC8"
                  d="M57.5437466,118.787869 C77.1808358,118.787869 93.4265375,115.267807 96.1298397,110.688535 C96.3904557,111.129015 96.5249942,111.579634 96.5249942,112.03787 C96.5249942,117.25696 79.0724952,121.487869 57.5437466,121.487869 C36.014998,121.487869 18.5624989,117.25696 18.5624989,112.03787 C18.5624989,111.579286 18.6972418,111.128331 18.9576535,110.687205 C21.6585809,115.267293 37.9052221,118.787869 57.5437466,118.787869 Z"/>
            <path fill="#D2CCC8"
                  d="M57.5437466 112.37537C77.1808358 112.37537 93.4265375 108.855308 96.1298397 104.276035 96.3904557 104.716516 96.5249942 105.167134 96.5249942 105.62537 96.5249942 110.844461 79.0724952 115.07537 57.5437466 115.07537 36.014998 115.07537 18.5624989 110.844461 18.5624989 105.62537 18.5624989 105.166786 18.6972418 104.715831 18.9576535 104.274706 21.6585809 108.854793 37.9052221 112.37537 57.5437466 112.37537zM57.5437466 83.0128716C77.1808358 83.0128716 93.4265375 79.4928095 96.1298397 74.9135367 96.3904557 75.3540174 96.5249942 75.8046358 96.5249942 76.262872 96.5249942 81.4819626 79.0724952 85.7128714 57.5437466 85.7128714 36.014998 85.7128714 18.5624989 81.4819626 18.5624989 76.262872 18.5624989 75.8042879 18.6972418 75.3533331 18.9576535 74.9122074 21.6585809 79.4922949 37.9052221 83.0128716 57.5437466 83.0128716z"/>
            <path fill="#F1F0EF"
                  d="M39.8249976,34.074998 L75.2624955,34.074998 L75.2624955,43.1874975 C75.2624955,45.2378545 67.3295414,46.8999973 57.5437466,46.8999973 C47.7579517,46.8999973 39.8249976,45.2378545 39.8249976,43.1874975 L39.8249976,43.1874975 L39.8249976,34.074998 Z"/>
            <g transform="rotate(-60 63.746 -46.503)">
                <path fill="#BDD6ED"
                      d="M17.7187489,2.02499988 C22.2744074,2.02499988 26.4285079,2.66358729 29.5676039,3.71291048 L35.4374979,3.71249978 L35.4374979,8.77499948 C35.1915384,12.3314593 27.3531142,15.1874991 17.7187489,15.1874991 C8.08438367,15.1874991 0.245959492,12.3314593 0.00567629459,8.77448252 L0,8.77499948 L0,3.71249978 L5.86994756,3.71289258 C9.00903719,2.66358003 13.1631164,2.02499988 17.7187489,2.02499988 Z"/>
                <ellipse cx="17.719" cy="3.712" fill="#99BDDE" rx="17.719" ry="3.712"/>
            </g>
            <ellipse cx="57.544" cy="34.075" fill="#D2CCC8" rx="17.719" ry="3.712"/>
            <path fill="#F1F0EF"
                  d="M46.9124972,29.4654588 L68.1749968,29.4654588 L68.1749968,31.8279587 C68.1749968,33.0689642 63.4152241,34.074998 57.543747,34.074998 C51.6722699,34.074998 46.9124972,33.0689642 46.9124972,31.8279587 L46.9124972,31.8279587 L46.9124972,29.4654588 Z"/>
            <ellipse cx="57.544" cy="29.519" fill="#D2CCC8" rx="10.631" ry="2.194"/>
            <ellipse cx="57.544" cy="29.35" fill="#928782" rx="4.894" ry="1.012"/>
            <mask id="semen-stock-b" fill="#fff">
                <use xlink:href="#semen-stock-a"/>
            </mask>
            <use fill="#928782" opacity="0" xlink:href="#semen-stock-a"/>
            <g mask="url(#semen-stock-b)">
                <g transform="translate(60.75 27.325)">
                    <path fill="#A29894"
                          d="M5.45790552,0 C5.63692664,-2.39868735e-16 5.80861552,0.071115863 5.93520257,0.197702911 L6.24374963,0.50624997 C6.3678309,0.630331246 6.3678309,0.831506742 6.24374963,0.955588018 C6.18804366,1.01129399 6.11362913,1.04431846 6.03494728,1.04825256 L1.349,1.282 L1.34999992,3.03749982 C1.34999992,3.410292 1.04779214,3.71249978 0.67499996,3.71249978 C0.302207776,3.71249978 -6.56795492e-14,3.410292 -6.57252031e-14,3.03749982 L-6.57252031e-14,0.67499996 C-6.57708569e-14,0.302207776 0.302207776,6.84808132e-17 0.67499996,0 L5.45790552,0 Z"/>
                    <path fill="#7F746F"
                          d="M5.73749966,0.33749998 C6.11029184,0.33749998 6.41249962,0.639707756 6.41249962,1.01249994 C6.41249962,1.38529212 6.11029184,1.6874999 5.73749966,1.6874999 L1.68649998,1.68649998 L1.6874999,3.03749982 C1.6874999,3.410292 1.38529212,3.71249978 1.01249994,3.71249978 C0.639707756,3.71249978 0.33749998,3.410292 0.33749998,3.03749982 L0.33749998,1.01249994 C0.33749998,0.639707756 0.639707756,0.33749998 1.01249994,0.33749998 L5.73749966,0.33749998 Z"/>
                </g>
            </g>
            <g mask="url(#semen-stock-b)">
                <g transform="matrix(-1 0 0 1 54.337 27.325)">
                    <path fill="#A29894"
                          d="M5.45790552,0 C5.63692664,-2.39868735e-16 5.80861552,0.071115863 5.93520257,0.197702911 L6.24374963,0.50624997 C6.3678309,0.630331246 6.3678309,0.831506742 6.24374963,0.955588018 C6.18804366,1.01129399 6.11362913,1.04431846 6.03494728,1.04825256 L1.349,1.282 L1.34999992,3.03749982 C1.34999992,3.410292 1.04779214,3.71249978 0.67499996,3.71249978 C0.302207776,3.71249978 -6.56795492e-14,3.410292 -6.57252031e-14,3.03749982 L-6.57252031e-14,0.67499996 C-6.57708569e-14,0.302207776 0.302207776,6.84808132e-17 0.67499996,0 L5.45790552,0 Z"/>
                    <path fill="#7F746F"
                          d="M5.73749966,0.33749998 C6.11029184,0.33749998 6.41249962,0.639707756 6.41249962,1.01249994 C6.41249962,1.38529212 6.11029184,1.6874999 5.73749966,1.6874999 L1.68649998,1.68649998 L1.6874999,3.03749982 C1.6874999,3.410292 1.38529212,3.71249978 1.01249994,3.71249978 C0.639707756,3.71249978 0.33749998,3.410292 0.33749998,3.03749982 L0.33749998,1.01249994 C0.33749998,0.639707756 0.639707756,0.33749998 1.01249994,0.33749998 L5.73749966,0.33749998 Z"/>
                </g>
            </g>
            <rect width="1.687" height="4.387" x="56.7" y="26.65" fill="#A29894" mask="url(#semen-stock-b)" rx=".337"/>
            <g transform="rotate(10 -168.1 127.787)">
                <g transform="translate(4.563 .375)">
                    <path fill="#A29894"
                          d="M5.52740487,0.073746581 C5.90019697,0.0734904966 6.20261227,0.375490604 6.20286835,0.7482827 L6.20195774,0.783799346 L6.20195774,0.783799346 L6.19942181,0.832566848 C6.17747502,1.25461647 5.82237214,1.58181679 5.39993979,1.56922876 L1.85088329,1.46325782 L1.85140651,105.373252 C1.85140651,105.746044 1.54919873,106.048252 1.17640655,106.048252 C0.803614365,106.048252 0.50140659,105.746044 0.50140659,105.373252 L0.500822712,1.24174485 C0.432427103,1.17404994 0.377993536,1.0912316 0.343067877,0.997268829 L0.251307612,0.750400235 C0.155219274,0.491887542 0.286890229,0.20442661 0.545402921,0.108338272 C0.600967147,0.0876852275 0.659764806,0.077090339 0.719043215,0.0770496185 L1.13703152,0.0743871501 C1.15006092,0.0736377076 1.16318937,0.073257825 1.17640655,0.073257825 L1.21388329,0.076257825 L5.52740487,0.073746581 Z"
                          transform="matrix(-1 0 0 1 6.423 0)"/>
                    <path fill="#7F746F"
                          d="M5.73749966,0.33749998 C6.11029184,0.33749998 6.41249962,0.639707756 6.41249962,1.01249994 C6.41249962,1.38529212 6.11029184,1.6874999 5.73749966,1.6874999 L1.68649998,1.68649998 L1.6874999,105.299994 C1.6874999,105.672786 1.38529212,105.974994 1.01249994,105.974994 C0.639707756,105.974994 0.33749998,105.672786 0.33749998,105.299994 L0.33749998,1.01249994 C0.33749998,0.639707756 0.639707756,0.33749998 1.01249994,0.33749998 L5.73749966,0.33749998 Z"
                          transform="matrix(-1 0 0 1 6.75 0)"/>
                </g>
                <path fill="#A29894"
                      d="M-1.8189894e-12,85.4718701 L11.1374993,85.4718701 L11.1374993,110.446869 C11.1374993,111.378849 8.64428519,112.134369 5.56874967,112.134369 C2.49321415,112.134369 -1.8189894e-12,111.378849 -1.8189894e-12,110.446869 L-1.8189894e-12,110.446869 L-1.8189894e-12,85.4718701 Z"/>
                <ellipse cx="5.569" cy="85.472" fill="#928782" rx="5.569" ry="1.687"/>
                <ellipse cx="5.569" cy="85.472" fill="#5D5551" rx="4.556" ry="1.35"/>
            </g>
        </g>
    </g>
</svg>
<!-- eslint-enable -->
</template>