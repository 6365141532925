<template>
  <v-text-field
    v-bind="$attrs"
    persistent-placeholder
    :model-value="formattedValue"
    type="text"
    @keypress="filter(event)"
    @paste="paste(event)"
    @update:model-value="handleUpdate"
  />
</template>


<script>

// 1. a decimal value is always emitted with a decimal point even for locales that use a decimal comma
// 2. the decimal separator that is displayed depends on the locale
// 3. it's possible to enter both a decimal point or comma as a separator. they will converted directly to the correct decimal separator depending on the locale
// 4. scientific notation not supported
// 5. currency not supported
// 6. number can optionally start with minus sign (set allowMinus to true)
// 7. regex checks performed on keypress event 
// 8. paste event is disabled
// 8. "rules" prop (like all other props) is passed on to v-text-field for complex validations


export default {
    name: 'CrvNumberInput',

    props: {
        modelValue: {
            type: Number,
            default: undefined,
        },

        numberOfDecimals: {
            type: Number,
            default: 0,  // 0: Integer, >= 1: Decimal
        },

        allowMinus: {
            type: Boolean,
            default: false,
        },

        locale: {
            type: String,
            default: 'nl',
            note: 'locale, f.e. nl, nl-NL, en-US',
        },
    },

    emits: {
        'update:model-value': false, // see handleUpdate for reason why disabled
    },

    data() {
        return {
            localModelValue: this.modelValue,
        }
    },

    computed: {
        regExp() {
            let pattern
            if (this.numberOfDecimals === 0) {
                pattern = '[0-9]*'
            } else {
                pattern = `[0-9]*[\\.|,]?[0-9]{0,${this.numberOfDecimals}}`
            }
            if (this.allowMinus) {
                pattern = `\\-?${pattern}`
            }
            pattern = `^${pattern}$`
            return new RegExp(pattern)
        },

        decimalSeparator() {
            return Intl.NumberFormat(this.locale)
                .formatToParts(1.1)
                .find(part => part.type === 'decimal')
                .value
        },

        formattedValue() {
            if (this.localModelValue == null) {
                return this.localModelValue
            } 
            if (this.decimalSeparator === '.') {
                return this.localModelValue.toString().replace(',', this.decimalSeparator)
            }
            if (this.decimalSeparator === ',') {
                return this.localModelValue.toString().replace('.', this.decimalSeparator)
            }
            return this.localModelValue
        }
    },

    watch: {
        modelValue() {
            this.localModelValue = this.modelValue
        },
    },

    methods: {
        filter(evt) {
            evt = (evt) ? evt : window.event
            const oldValue = evt.target.value.toString()
            const key = evt.key.toString()
            const index = evt.target.selectionStart
            const newValue = oldValue.slice(0, index) + key + oldValue.slice(index)
            if (this.regExp.test(newValue)) {
                return true
            } else {
                evt.preventDefault()
            }
        },

        paste(evt) {
            evt = (evt) ? evt : window.event
            evt.preventDefault()
        },

        // update:model-value from vuetify replaced by handleUpdate to prevent
        // modelValue getting a value ending on a decimal separator
        handleUpdate(value) {
            this.localModelValue = value
            const rawValue = value.replace(',', '.')
            if (rawValue.endsWith('.')) {
                return
            }
            if (rawValue === '-') {
                return
            }
            if (rawValue === '') {
                this.$emit('update:model-value', undefined)
                return
            }
            this.$emit('update:model-value', +rawValue)
        },
    },
}
</script>