<template>
  <!-- eslint-disable -->
    <svg xmlns="http://www.w3.org/2000/svg" class="crv-svg-fallback" viewBox="0 0 512 385">
      <defs>
        <radialGradient :id="`no-search-results-${uid}`" cy="42.187%" r="114.036%" fx="50%" fy="42.187%" gradientTransform="matrix(.43846 0 0 .46361 .28 .226)">
          <stop offset="0%" stop-color="#E0EDF8"/>
          <stop offset="100%" stop-color="#B8D0E7"/>
        </radialGradient>
      </defs>
      <g fill="none" fill-rule="evenodd" transform="translate(0 190)">
        <ellipse cx="256" cy="145" fill="#F7F6F6" rx="256" ry="50"/>
        <g transform="translate(66)">
          <path fill="#BCB4B1" d="M241.2,79.3670886 C241.2,113.238223 187.205541,140.696203 120.6,140.696203 C53.9944592,140.696203 0,113.238223 0,79.3670886 L0,61.3291139 C1.19891784e-14,27.4579796 53.9944592,0 120.6,0 C187.205541,0 241.2,27.4579796 241.2,61.3291139 L241.2,79.3670886 Z M1.29489401,70.3481013 C9.87193494,99.9432845 60.0196331,122.658228 120.6,122.658228 C181.180367,122.658228 231.328065,99.9432845 239.905106,70.3481013 C231.328065,40.752918 181.180367,18.0379747 120.6,18.0379747 C60.0196331,18.0379747 9.87193494,40.752918 1.29489401,70.3481013 Z"/>
          <path :fill="`url(#no-search-results-${uid})`" d="M1.29489401,70.3481013 C9.87193494,40.752918 60.0196331,18.0379747 120.6,18.0379747 C181.180367,18.0379747 231.328065,40.752918 239.905106,70.3481013 C231.328065,99.9432845 181.180367,122.658228 120.6,122.658228 C60.0196331,122.658228 9.87193494,99.9432845 1.29489401,70.3481013 Z" opacity=".527"/>
          <path fill="#E4E0DE" d="M120.6,122.658228 C53.9944592,122.658228 0,95.2002483 0,61.3291139 C0,27.4579796 53.9944592,0 120.6,0 C187.205541,0 241.2,27.4579796 241.2,61.3291139 C241.2,95.2002483 187.205541,122.658228 120.6,122.658228 Z M120.6,119.050633 C184.554574,119.050633 236.4,93.2078286 236.4,61.3291139 C236.4,29.4503993 184.554574,3.60759494 120.6,3.60759494 C56.645426,3.60759494 4.8,29.4503993 4.8,61.3291139 C4.8,93.2078286 56.645426,119.050633 120.6,119.050633 Z"/>
          <g transform="rotate(23 -121.982 577.271)">
            <path fill="#E4E0DE" d="M10.6462941,-3.56293908e-16 L22.4755098,-9.70593749e-16 L22.4755098,0.0184137443 C27.7533389,0.347947043 31.9388824,5.05924066 31.9388824,10.8227848 C31.9388824,16.586329 27.7533389,21.2976226 22.4755098,21.6271559 L22.4755098,21.6455696 L10.6462941,21.6455696 L10.6462941,21.6271559 C10.4505898,21.6393751 10.2533837,21.6455696 10.0548333,21.6455696 C4.50170223,21.6455696 0,16.8000438 0,10.8227848 C0,4.84552581 4.50170223,-3.72007468e-17 10.0548333,-3.25578916e-16 C10.2533837,-3.35889779e-16 10.4505898,0.00619449886 10.6462941,0.0184137443 L10.6462941,-3.56293908e-16 Z"/>
            <path fill="#BCB4B1" d="M27.2071961,1.85583215e-16 L182.169922,-7.8617447e-15 L182.169922,0.0184137443 C182.365626,0.00619449886 182.562832,-7.88214883e-15 182.761383,-7.89245969e-15 C188.314514,-8.18083786e-15 192.816216,4.84552581 192.816216,10.8227848 C192.816216,16.8000438 188.314514,21.6455696 182.761383,21.6455696 C182.562832,21.6455696 182.365626,21.6393751 182.169922,21.6271559 L182.169922,21.6455696 L27.2071961,21.6455696 L27.2071961,21.6271559 C27.0114918,21.6393751 26.8142856,21.6455696 26.6157353,21.6455696 C21.0626042,21.6455696 16.560902,16.8000438 16.560902,10.8227848 C16.560902,4.84552581 21.0626042,5.04676376e-16 26.6157353,2.16298207e-16 C26.8142856,2.05987344e-16 27.0114918,0.00619449886 27.2071961,0.0184137443 L27.2071961,1.85583215e-16 Z"/>
            <ellipse cx="185.4" cy="10.823" fill="#D2CCC8" rx="10.2" ry="10.823"/>
          </g>
        </g>
      </g>
    </svg>
    <!-- eslint-enable -->
</template>

<script>
export default {
    static() {
        const uid = this._uid  
        return {
            uid,
        }
    },
}
</script>
